<template>
  <v-app>
    <v-container fluid fill-height>
      <v-row align="center" justify="center">
        <v-col xs="12" sm="10" md="7" lg="6" xl="4">
          <v-img src="@/assets/logo.png" width="300" class="mx-auto pa-10"></v-img>
        </v-col>
      </v-row>

      <v-row align="center" justify="center" class="login-row">
        <v-col xs="12" sm="10" md="7" lg="6" xl="4" class="card">
          <v-card elevation="2">
            <v-toolbar dark color="#283743">
              <v-toolbar-title>Se connecter</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field
                  label="Adresse email"
                  required
                  v-model="email"
                  type="email"
                ></v-text-field>
                <v-text-field
                  label="Mot de passe"
                  required
                  type="password"
                  v-model="password"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-alert v-if="error" color="red lighten-2" type="error"
              >Adresse mail ou mot de passe incorrect</v-alert
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" large outlined rounded @click="submit">
                Se connecter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import constants from "@/store/constants";
import { mapActions } from "vuex";
import Routes from "../router/Routes";
export default {
  name: "Login",

  data() {
    return {
      email: "",
      password: "",
      error: false,
    };
  },

  methods: {
    ...mapActions([constants.Actions.LOGIN]),
    async submit() {
      if (this.email && this.password) {
        try {
          await this.login({ email: this.email, password: this.password });
          await this.$router.push({ name: Routes.DASHBOARD });
        } catch (error) {
          this.error = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .login-row {
    margin-top: -30vh;
  }
</style>