











































































































































import Routes from "../router/Routes";
import Vue from "vue";
import constants from '@/store/constants';
import { mapActions, mapGetters, mapState } from 'vuex';

export default Vue.extend({
  name: "TheSidebar",

  props: {
    drawer: {
      required: true,
    },
  },

  data: () => ({
      selectedItem: null,
      Routes,
  }),

  methods: {
    ...mapActions([constants.Actions.GET_ALL_EVENTS]),

    async handleAddRace(eventId: string) {
      this.selectedItem = null;
      this.$router.push({ name: Routes.EVENT_ADD_RACE, params: { id: eventId } });
    },

    handleGoToEvents() {
      this.selectedItem = null;
      this.$router.push({ name: Routes.EVENTS });
    },

    handleGoToHome() {
      this.selectedItem = null;
      this.$router.push({ name: Routes.DASHBOARD });
    }
  },

  computed: {
    ...mapGetters([constants.Getters.EVENTS])
  },

  async created() {
    try {
      this.getAllEvents();
    } catch(err) {
      console.error('Could not fetch events', err);
    }
  },
});
