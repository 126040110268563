<template>
  <v-container>
    <v-row>
      <v-alert v-if="createEventError" color="red lighten-2" type="error" class="alert"
        >Impossible de créer l'événement.</v-alert
      >
    </v-row>

    <v-row class="align-center">
      <v-dialog
        v-model="newEventDialog"
        max-width="600px"
      >

        <v-card>
          <v-card-title class="text-h5">
            Nouvel événement
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field
                    label="Nom de l'événement"
                    required
                    v-model="newEventName"
                    autofocus
                    @keyup.enter="handleAddEvent"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="newEventDialog = false"
            >
              Annuler
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="handleAddEvent"
            >
              Sauvegarder
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-col cols="6">
        <v-row no-gutters class="align-center">
          <v-col cols="1">
            <v-icon
              x-large
              color="primary"
            >
              mdi-tune
            </v-icon>
          </v-col>

          <v-col>
            <h1>Événements</h1>
          </v-col>

          <v-col>
            <v-btn
              rounded
              outlined
              small
              color="secondary"
              @click="newEventDialog = true"
            >
              <v-icon left>
                mdi-plus-circle-outline
              </v-icon>
              Nouvel événement
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="align-center">
      <v-dialog
        v-model="deleteEventDialog"
        max-width="600px"
      >

        <v-card>
          <v-card-title class="text-h7">
            Êtes-vous sûr de vouloir supprimer cet événement ?
          </v-card-title>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="deleteEventDialog = false"
            >
              Annuler
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="handleRemoveEvent(deleteSelectedEventId)"
            >
              Supprimer
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-row class="mt-8">
      <v-col cols="5">
        <v-row
          v-for="event in events"
          :key="event.id"
          align='baseline'
        >
          <v-col>
            <p class="font-weight-medium">{{ event.name }}</p>
          </v-col>

          <v-col>
            <v-btn
              fab
              x-small
              rounded
              outlined
              color="red"
              class="remove-score-url-btn"
              @click.prevent="handleDeleteEventClick(event.id)"
            >
              <v-icon dark>
                mdi-close
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import constants from '@/store/constants';

export default {
  data: () => ({
    newEventDialog: false,
    deleteEventDialog: false,
    deleteSelectedEventId: null,
    newEventName: null,
    createEventError: false,
  }),

  methods: {
    ...mapActions([
      constants.Actions.GET_ALL_EVENTS,
      constants.Actions.CREATE_EVENT,
      constants.Actions.DELETE_EVENT,
    ]),

    async handleAddEvent() {
      if (!this.newEventName) return;

      this.newEventDialog = false;
      try {
        await this.createEvent(this.newEventName);
      } catch(err) {
        console.error(err);
        this.createEventError = true;
        setTimeout(() => {
          this.createRaceError = false;
        }, 2000);
      }

      this.newEventName = null;
    },

    async handleRemoveEvent(eventId) {
      this.deleteEventDialog = false;
      this.deleteSelectedEventId = null;

      try {
        await this.deleteEvent(eventId);
      } catch(err) {
        console.error('Could not delete event', err);
      }
    },

    handleDeleteEventClick(eventId) {
      this.deleteSelectedEventId = eventId;
      this.deleteEventDialog = true;
    }
  },

  computed: {
    ...mapGetters([constants.Getters.EVENTS])
  },

  async created() {
    try {
      this.getAllEvents();
    } catch(err) {
      console.error('Could not fetch events', err);
    }
  },
}
</script>

<style lang="scss" scoped>
  .alert {
    position: fixed;
    top: 10%;
    left: 45%;
    z-index: 100;
  }
</style>