<template>
  <v-container>
    <v-row>
      <v-alert
        :value="updateIntervalSuccess"
        transition="fade-transition"
        type="success"
        class="alert"
      >
        L'écart a été mise à jour.
      </v-alert>

      <v-alert
        :value="updateIntervalError"
        transition="fade-transition"
        type="error"
        class="alert"
      >
        Impossible de mettre à jour l'interval.
      </v-alert>

      <v-alert
        :value="createIntervalSuccess"
        transition="fade-transition"
        type="success"
        class="alert"
      >
        L'écart a été crée avec succès.
      </v-alert>

      <v-alert
        :value="createIntervalError"
        transition="fade-transition"
        type="error"
        class="alert"
      >
        Impossible de créer l'interval.
      </v-alert>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-row no-gutters class="align-center">
          <v-col cols="1">
            <v-icon x-large color="primary"> mdi-arrow-left-right </v-icon>
          </v-col>

          <v-col>
            <h1>Écarts</h1>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row v-for="(interval, i) in intervals" :key="i">
          <v-col>
            <v-card elevation="1" class="mt-3">
              <v-card-text class="pa-0" v-if="intervals">
                <v-form>
                  <v-container>
                    <v-row class="align-center">
                      <v-col cols="8">
                        <v-row>
                          <v-col>
                            <v-row>
                              <v-col class="text-center">
                                <v-row class="flex-column" v-if="interval.id">
                                  <v-col>
                                    <span class="text-caption"
                                      >Statut calcul écart:</span
                                    >
                                  </v-col>

                                  <v-col>
                                    <div
                                      class="pa-2 status-circle rounded-circle d-inline-block"
                                      :id="[
                                        interval.id
                                          ? 'status-circle-' + interval.id
                                          : '',
                                      ]"
                                    ></div>
                                  </v-col>
                                </v-row>
                              </v-col>
                              <v-col class="ml-20">
                                <v-select
                                  v-model="interval.teamReference.team"
                                  :items="teams"
                                  item-text="name"
                                  item-value="id"
                                  label="Équipe devant"
                                  persistent-hint
                                ></v-select>
                              </v-col>
                              <v-col>
                                <v-text-field
                                  label="Label"
                                  v-model="interval.teamReference.label"
                                ></v-text-field>
                              </v-col>

                              <v-col>
                                <v-select
                                  v-model="interval.intervals[0].team"
                                  :items="teams"
                                  item-text="name"
                                  item-value="id"
                                  label="Équipe derrière"
                                  persistent-hint
                                ></v-select>
                              </v-col>
                              <v-col>
                                <v-text-field
                                  label="Label"
                                  v-model="interval.intervals[0].label"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col>
                        <v-row align="center" justify="end">
                          <v-col cols="4">
                            <v-btn
                              rounded
                              outlined
                              small
                              :disabled="
                                !interval.teamReference ||
                                interval.intervals.length === 0
                              "
                              color="secondary"
                              @click="handleSaveInterval(i)"
                            >
                              <v-icon left>
                                {{
                                  interval.id
                                    ? "mdi-pencil-outline"
                                    : "mdi-plus-circle-outline"
                                }}
                              </v-icon>
                              {{ interval.id ? "Modifier" : "Créer" }}
                            </v-btn>
                          </v-col>
                          <v-col cols="4" class="mr-10 ml-5">
                            <v-row class="justify-end ml-6">
                              <v-btn
                                rounded
                                outlined
                                small
                                color="red"
                                class="remove-btn"
                                @click.prevent="handleDeleteIntervalClick(i)"
                              >
                                <v-icon dark left> mdi-close </v-icon>
                                Supprimer
                              </v-btn>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-if="interval.id"
                              label="Web URL"
                              readonly
                              :value="getHTMLOutputURL(interval)"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-if="interval.id"
                              label="JSON URL"
                              readonly
                              :value="getJSONOutputURL(interval)"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col cols="3">
                        <v-text-field
                          v-if="interval.id"
                          label="Écart distance kms (live)"
                          readonly
                          v-model="interval.intervals[0].kms"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="2" class="ml-3">
                        <v-text-field
                          v-if="interval.id"
                          label="Écart temps (live)"
                          readonly
                          v-model="interval.intervals[0].time"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-2" justify="end">
          <v-col cols="2">
            <v-btn
              rounded
              outlined
              small
              color="secondary"
              @click="handleAddInterval"
            >
              <v-icon left> mdi-plus-circle-outline </v-icon>
              Nouvel écart
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="align-center">
          <v-dialog v-model="deleteIntervalDialog" max-width="600px">
            <v-card>
              <v-card-title class="text-h7">
                Êtes-vous sûr de vouloir supprimer cet Écart ?
              </v-card-title>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  text
                  @click="deleteIntervalDialog = false"
                >
                  Annuler
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="handleDeleteInterval(deleteSelectedIntervalIndex)"
                >
                  Supprimer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import constants from "@/store/constants";
import { mapActions, mapGetters } from "vuex";
import hostame from "@/utils/hostname";
import config from "@/config";
import WebSocketRepository from "@/api/webSockets/WebSocketRepository";
const wsRepository = new WebSocketRepository();

export default {
  data() {
    return {
      deleteSelectedIntervalIndex: null,
      deleteIntervalDialog: false,
      updateIntervalSuccess: false,
      updateIntervalError: false,
      createIntervalSuccess: false,
      createIntervalError: false,
      fetchDataTicker: null,
      activeTimeoutMs: 30000,
    };
  },

  methods: {
    ...mapActions([
      constants.Actions.GET_ALL_INTERVALS,
      constants.Actions.CREATE_INTERVAL,
      constants.Actions.UPDATE_INTERVAL,
      constants.Actions.DELETE_INTERVAL,

      constants.Actions.GET_ALL_TEAMS,
    ]),

    getHTMLOutputURL(interval) {
      // join baseURL + path to automatically resolve '/' issues
      // e.g if baseURL has a '/' at the end and path has also a '/' in front: avoids having '//'
      return new URL(`/live/intervals/${interval.id}`, hostame()).href;
    },

    getJSONOutputURL(interval) {
      return new URL(`/intervals/${interval.id}`, config.API_HOST).href;
    },

    handleAddInterval() {
      const defaultIntervalItem = {
        id: null,
        teamReference: {
          team: null,
          label: null,
        },
        intervals: [
          {
            team: null,
            label: null,
          },
        ],
      };

      this.intervals.push(defaultIntervalItem);
    },

    handleDeleteIntervalClick(itemIndex) {
      const interval = this.intervals[itemIndex];
      if (!interval) return;

      // if Interval from api then show a confirmation dialog
      if (interval.id) {
        this.deleteSelectedIntervalIndex = itemIndex;
        this.deleteIntervalDialog = true;
      } else {
        this.handleDeleteInterval(itemIndex);
      }
    },

    handleDeleteInterval(itemIndex) {
      const intervalToRemove = this.intervals[itemIndex];
      if (!intervalToRemove) return;

      this.deleteSelectedIntervalIndex = null;
      this.deleteIntervalDialog = false;

      // it's an interval obj from the API and not one created locally
      if (intervalToRemove.id) {
        try {
          this.deleteInterval({
            intervalId: intervalToRemove.id,
          });
        } catch (err) {
          console.error("delete interval from api error", err);
        }
      }
      this.intervals.splice(itemIndex, 1);
    },

    async handleSaveInterval(itemIndex) {
      const interval = this.intervals[itemIndex];
      if (!interval) return;

      // interval has id so it's from api so update it
      if (interval.id) {
        try {
          const intervalIntervals = interval.intervals.map((interval) => {
            interval.team = interval.team.id ? interval.team.id : interval.team;
            delete interval["kms"];
            delete interval["time"];
            delete interval["timestamp"];
            return interval;
          });

          await this.updateInterval({
            intervalId: interval.id,
            updateIntervalDto: {
              teamReference: {
                team: interval.teamReference.team.id
                  ? interval.teamReference.team.id
                  : interval.teamReference.team,
                label: interval.teamReference.label,
              },
              intervals: intervalIntervals,
            },
          });

          this.updateIntervalSuccess = true;
          setTimeout(() => {
            this.updateIntervalSuccess = false;
          }, 2000);
        } catch (err) {
          console.error("update interval error", err);
          this.updateIntervalError = true;
          setTimeout(() => {
            this.updateIntervalError = false;
          }, 2000);
        }
      } else {
        try {
          await this.createInterval({
            createIntervalDto: {
              teamReference: interval.teamReference,
              intervals: interval.intervals,
            },
          });

          this.createIntervalSuccess = true;
          setTimeout(() => {
            this.createIntervalSuccess = false;
          }, 2000);
        } catch (err) {
          console.error("create interval error", err);
          this.createIntervalError = true;
          setTimeout(() => {
            this.createIntervalError = false;
          }, 2000);
        }
      }
    },

    async registerWsHandlers() {
      wsRepository.registerHandler(
        "getIntervals",
        async (data) => await this.handleGetIntervalsResp(data)
      );
    },

    async handleGetIntervalsResp(data) {
      for (const interval of data) {
        let intervalActive = false;

        for (const teamInterval of interval.intervals) {
          // if at least one team interval timestamp is recent
          if (Date.now() - teamInterval.timestamp < this.activeTimeoutMs) {
            intervalActive = true;
            break;
          }
        }

        this.setIntervalActive(interval.id, intervalActive);

        // Update time and kms live data
        const intervalToUpdate = this.intervals.find(
          (interval) => interval.id === interval["id"]
        );
        intervalToUpdate["intervals"][0]["kms"] =
          interval["intervals"][0]["kms"];
        intervalToUpdate["intervals"][0]["time"] =
          interval["intervals"][0]["time"];
      }
    },

    startDataFetchTicker(timeout) {
      // first request data so it is displayed instantly, then wait for time intervals
      wsRepository.getIntervals();

      if (this.fetchDataTicker === null) {
        this.fetchDataTicker = setInterval(() => {
          wsRepository.getIntervals();
        }, timeout);
      }
    },

    setIntervalActive(liveStreamId, isActive) {
      const circleElem = document.querySelector(
        `#status-circle-${liveStreamId}`
      );
      if (!circleElem)
        throw new Error(`No status circle element with id ${circleElem}`);

      if (isActive) {
        circleElem.classList.add("active");
      } else {
        circleElem.classList.remove("active");
      }
    },
  },

  computed: {
    ...mapGetters([constants.Getters.INTERVALS, constants.Getters.TEAMS]),
  },

  async created() {
    try {
      await this.getAllTeams();
    } catch (err) {
      console.error("Could not fetch teams", err);
    }

    try {
      await this.getAllIntervals();
    } catch (err) {
      console.error("Could not fetch intervals", err);
    }

    this.registerWsHandlers();
    this.startDataFetchTicker(4000);
  },
};
</script>

<style lang="scss" scoped>
// disables focus on button after clicking on it:
// https://stackoverflow.com/questions/57830767/is-it-default-for-vuetify-to-keep-active-state-on-buttons-after-click-how-do-yo
.remove-btn:focus:before {
  opacity: 0 !important;
}

.status-circle {
  background-color: tomato;

  &.active {
    background-color: rgb(84, 185, 84);
  }
}

.alert {
    position: fixed;
    top: 10%;
    left: 50%;
    z-index: 100;
  }
</style>
