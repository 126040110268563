import Repository, { CreateRoleDto, CreateTeamDto } from "@/api/Repository";
import router from "@/router";
import Routes from "@/router/Routes";
import LocalStorageKeys from "@/utils/localStorage/LocalStorageKeys";
import localStorageUtils from "@/utils/localStorage/localStorageUtils";
import Vue from "vue";
import Vuex from "vuex";
import constants from "./constants";

Vue.use(Vuex);
Vue.config.devtools = true;

interface PromiseRejectedResult {
  status: "rejected";
  reason: any;
}

export default new Vuex.Store({
  state: {
    user: localStorageUtils.getParsedItemtOrNull(LocalStorageKeys.USER),
    events: [],
    race: null,
    teams: [],
    teamsNotReferencedByLiveStreams: [],
    roles: [],
    // races specific to one particular event
    races: [],
    // races of all events
    allEventsRaces: [],
    liveStreams: [],
    intervals: [],
    fetchedCrossingPoints: [],
  },
  getters: {
    [constants.Getters.USER]: (state) => state.user,
    [constants.Getters.AUTHENTICATED]: (state) => state.user !== null,
    [constants.Getters.EVENTS]: (state) => state.events,
    [constants.Getters.TEAMS]: (state) => state.teams,
    [constants.Getters.TEAMS_NOT_REFERENCED_BY_LIVESTREAMS]: (state) => state.teamsNotReferencedByLiveStreams,
    [constants.Getters.ROLES]: (state) => state.roles,
    [constants.Getters.RACE]: (state) => state.race,
    [constants.Getters.RACES]: (state) => state.races,
    [constants.Getters.ALL_EVENTS_RACES]: (state) => state.allEventsRaces,
    [constants.Getters.LIVE_STREAMS]: (state) => state.liveStreams,
    [constants.Getters.INTERVALS]: (state) => state.intervals,
    [constants.Getters.FETCHED_CROSSING_POINTS]: (state) => state.fetchedCrossingPoints,
  },
  mutations: {
    [constants.Mutations.SET_USER](state, user) {
      state.user = user;
    },
    [constants.Mutations.SET_ACCESS_TOKEN](state, accessToken: string) {
      state.user.tokens["access_token"] = accessToken;
    },
    [constants.Mutations.SET_EVENTS](state, events: []) {
      state.events = events;
    },
    [constants.Mutations.SET_TEAMS](state, teams: []) {
      state.teams = teams;
    },
    [constants.Mutations.SET_TEAMS_NOT_REFERENCED_BY_LIVESTREAMS](state, teamsNotReferencedByLiveStreams: []) {
      state.teamsNotReferencedByLiveStreams = teamsNotReferencedByLiveStreams;
    },
    [constants.Mutations.SET_ROLES](state, roles: []) {
      state.roles = roles;
    },
    [constants.Mutations.SET_RACE](state, race) {
      race.baseColor = race.baseColor || '#000d44';
      race.fillColor = race.fillColor || '#f42525';
      race.footerColor = race.footerColor || '#000000';
      state.race = race;
    },
    [constants.Mutations.SET_RACES](state, races) {
      state.races = races;
    },
    [constants.Mutations.SET_ALL_EVENTS_RACES](state, allEventsRaces) {
      state.allEventsRaces = allEventsRaces;
    },
    [constants.Mutations.SET_LIVE_STREAMS](state, liveStreams) {
      state.liveStreams = liveStreams;
    },
    [constants.Mutations.SET_INTERVALS](state, intervals) {
      state.intervals = intervals;
    },
    [constants.Mutations.SET_FETCHED_CROSSING_POINTS](state, crossingPoints) {
      state.fetchedCrossingPoints = crossingPoints;
    },
  },
  actions: {
    async [constants.Actions.LOGIN]({ commit }, user) {
      const { data } = await Repository.login(user);
      const userData = {
        ...data.user,
        tokens: data.tokens,
      };

      commit(constants.Mutations.SET_USER, userData);
      localStorage.setItem(LocalStorageKeys.USER, JSON.stringify(userData));
    },
    async [constants.Actions.LOGOUT]({ commit }, { logoutFromAPI }) {
      // logging out from api requires having a valid access_token token
      if (logoutFromAPI) {
        await Repository.logout();
      }

      commit(constants.Mutations.SET_USER, null);
      localStorage.removeItem(LocalStorageKeys.USER);
    },
    async [constants.Actions.UPDATE_ACCESS_TOKEN]({ commit }, { accessToken }) {
      commit(constants.Mutations.SET_ACCESS_TOKEN, accessToken);
      localStorage.setItem(
        LocalStorageKeys.USER,
        JSON.stringify(this.getters[constants.Getters.USER])
      );
    },
    async [constants.Actions.CREATE_EVENT]({ commit, dispatch }, eventName) {
      await Repository.createEvent(eventName);
      dispatch(constants.Actions.GET_ALL_EVENTS);
    },
    async [constants.Actions.DELETE_EVENT]({ commit, dispatch }, eventId) {
      await Repository.deleteEvent(eventId);
      dispatch(constants.Actions.GET_ALL_EVENTS);
      location.reload();
    },
    async [constants.Actions.CREATE_RACE]({ commit, dispatch }, { eventId, createRaceDto, filesFormData }) {
      try {
        const { data } = await Repository.createRace(eventId, createRaceDto);
        const raceId = data.id;

        // form data is not empty
        if (filesFormData.entries().next().value) {
          try {
            await Repository.uploadFilesToRace(eventId, raceId, filesFormData);

          } catch(err) {
            // TODO: display toast here?
            console.log('Could not upload files');
          }
        }

        dispatch(constants.Actions.GET_ALL_EVENTS);
        router.push({ name: Routes.RACE, params: { eventId, raceId } });
        // to display file inputs updated correclty
        location.reload();
      } catch(err) {
        console.error(`An error occured: ${err}`);
      }
    },
    async [constants.Actions.UPDATE_RACE]({ commit, dispatch }, { eventId, raceId, updateRaceDto, filesFormData }) {
      await Repository.updateRace(eventId, raceId, updateRaceDto);

      // form data is not empty
      if (filesFormData.entries().next().value) {
        await Repository.uploadFilesToRace(eventId, raceId, filesFormData);
      }
      // to display updates correclty
      location.reload();

      dispatch(constants.Actions.GET_ALL_EVENTS);
    },
    async [constants.Actions.DELETE_RACE]({ commit, dispatch }, { eventId, raceId }) {
      await Repository.deleteRace(eventId, raceId);
      dispatch(constants.Actions.GET_ALL_EVENTS);
      router.push({ name: Routes.DASHBOARD });
    },
    async [constants.Actions.GET_ALL_EVENTS]({ commit }) {
      const { data } = await Repository.getAllEvents();
      commit(constants.Mutations.SET_EVENTS, data.events);
    },
    async [constants.Actions.GET_RACE]({ commit }, {eventId, raceId}) {
      const { data } = await Repository.getRace(eventId, raceId);
      commit(constants.Mutations.SET_RACE, data);
    },
    async [constants.Actions.GET_FETCHED_CROSSING_POINTS]({ commit, dispatch }, { crossingPointsURL }) {
      const { data } = await Repository.fetchCrossingPoints(crossingPointsURL);
      commit(constants.Mutations.SET_FETCHED_CROSSING_POINTS, data);
    },
    async [constants.Actions.LOAD_CROSSING_POINTS]({ commit, dispatch }, { eventId, raceId }) {
      const { data } = await Repository.loadCrossingPoints(raceId);
      commit(constants.Mutations.SET_FETCHED_CROSSING_POINTS, data.crossingPoints);
      dispatch(constants.Actions.GET_ALL_EVENTS);
      return data;
    },
    async [constants.Actions.GET_ALL_RACES]({ commit }, {eventId}) {
      const { data } = await Repository.getEvent(eventId);
      commit(constants.Mutations.SET_RACES, data.races);
    },
    async [constants.Actions.GET_ALL_EVENTS_RACES]({ commit, getters }) {
      // get all events from state and map to call api with eventId
      const getAllEventsPromises = getters[constants.Getters.EVENTS]
        .map((event: any) => event.id)
        .map((eventId: string) => Promise.resolve(Repository.getEvent(eventId)));

      const allRaces: any = [];
      // wait all promises resolved
      const promiseResults: any = await Promise.allSettled(getAllEventsPromises);
      promiseResults.forEach((result: any) => {
        if (result.status === 'fulfilled') {
          allRaces.push(...result.value['data']['races']);
        }
      });

      commit(constants.Mutations.SET_ALL_EVENTS_RACES, allRaces);
    },
    async [constants.Actions.GET_ALL_TEAMS]({ commit }) {
      const { data } = await Repository.getAllTeams();
      commit(constants.Mutations.SET_TEAMS, data.teams);
    },
    async [constants.Actions.GET_ALL_TEAMS_NOT_REFERENCED_BY_LIVESTREAMS]({ commit }) {
      const { data } = await Repository.getAllTeamsNotReferencedByLiveStreams();
      commit(constants.Mutations.SET_TEAMS_NOT_REFERENCED_BY_LIVESTREAMS, data.teams);
    },
    async [constants.Actions.CREATE_TEAMS]({ commit, dispatch }, { teams }) {
      const createTeamPromises = teams.map((team: CreateTeamDto) => {
        return Promise.resolve(Repository.createTeam(team));
      });
      await Promise.allSettled(createTeamPromises);
      dispatch(constants.Actions.GET_ALL_TEAMS);
    },
    async [constants.Actions.DELETE_TEAMS]({ commit, dispatch }, { teamsIds }) {
      const deleteTeamPromises = teamsIds.map((teamId: string) => {
        return Promise.resolve(Repository.deleteTeam(teamId));
      });

      const promiseResults = await Promise.allSettled(deleteTeamPromises);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let deletionErrors: PromiseRejectedResult[] = promiseResults.filter(result => result.status === 'rejected') as PromiseRejectedResult[];
      deletionErrors = deletionErrors.map(result => result.reason.response.data.message);

      dispatch(constants.Actions.GET_ALL_TEAMS);

      return deletionErrors;
    },
    async [constants.Actions.GET_ALL_ROLES]({ commit }) {
      const { data } = await Repository.getAllRoles();
      commit(constants.Mutations.SET_ROLES, data.roles);
    },
    async [constants.Actions.CREATE_ROLES]({ commit, dispatch }, { roles }) {
      const createRolePromises = roles.map((role: CreateRoleDto) => {
        return Promise.resolve(Repository.createRole(role));
      });
      await Promise.allSettled(createRolePromises);
      dispatch(constants.Actions.GET_ALL_ROLES);
    },
    async [constants.Actions.DELETE_ROLES]({ commit, dispatch }, { rolesIds }) {
      const deleteRolePromises = rolesIds.map((roleId: string) => {
        return Promise.resolve(Repository.deleteRole(roleId));
      });

      const promiseResults = await Promise.allSettled(deleteRolePromises);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let deletionErrors: PromiseRejectedResult[] = promiseResults.filter(result => result.status === 'rejected') as PromiseRejectedResult[];
      deletionErrors = deletionErrors.map(result => result.reason.response.data.message);

      dispatch(constants.Actions.GET_ALL_ROLES);

      return deletionErrors;
    },

    async [constants.Actions.GET_ALL_LIVE_STREAMS]({ commit }) {
      const { data } = await Repository.getAllLiveStreams();
      commit(constants.Mutations.SET_LIVE_STREAMS, data.liveStreams);
    },
    async [constants.Actions.RESET_LIVE_STREAM]({ commit }, { liveStreamId, index }) {
      const { data } = await Repository.getLiveStream(liveStreamId);

      const liveStreams = this.getters[constants.Getters.LIVE_STREAMS];
      liveStreams.splice(index, 1, data);
      commit(constants.Mutations.SET_LIVE_STREAMS, liveStreams);
    },
    async [constants.Actions.CREATE_LIVE_STREAM]({ commit, dispatch }, { createLiveStreamDto }) {
      await Repository.createLiveStream(createLiveStreamDto);
      dispatch(constants.Actions.GET_ALL_LIVE_STREAMS);
      // reload page so it fetches again the teams notReferencedByLivestreams
      // avoid having the team just added to a new livestream available in the teams list for a new live
      location.reload();
    },
    async [constants.Actions.UPDATE_LIVE_STREAM]({ commit }, { liveStreamId, updateLiveStreamDto }) {
      await Repository.updateLiveStream(liveStreamId, updateLiveStreamDto);
    },
    async [constants.Actions.DELETE_LIVE_STREAM]({ commit, dispatch }, { liveStreamId }) {
      await Repository.deleteLiveStream(liveStreamId);
      dispatch(constants.Actions.GET_ALL_LIVE_STREAMS);
      // reload page so it fetches again the teams notReferencedByLivestreams
      // avoid not having team just being deleted available again in teams 
      location.reload();
    },

    async [constants.Actions.GET_ALL_INTERVALS]({ commit }) {
      const { data } = await Repository.getAllIntervals();
      commit(constants.Mutations.SET_INTERVALS, data.intervals);
    },
    async [constants.Actions.CREATE_INTERVAL]({ commit, dispatch }, { createIntervalDto }) {
      await Repository.createInterval(createIntervalDto);
      dispatch(constants.Actions.GET_ALL_INTERVALS);
    },
    async [constants.Actions.UPDATE_INTERVAL]({ commit, dispatch }, { intervalId, updateIntervalDto }) {
      await Repository.updateInterval(intervalId, updateIntervalDto);
      dispatch(constants.Actions.GET_ALL_INTERVALS);
    },
    async [constants.Actions.DELETE_INTERVAL]({ commit, dispatch }, { intervalId }) {
      await Repository.deleteInterval(intervalId);
      dispatch(constants.Actions.GET_ALL_INTERVALS);
    },
  },
});
