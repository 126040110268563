<template>
  <div id="cursor" :style="cssVars">
    <span id="kms">
      <div id="label" v-if="label">{{ label }}</div>
      <span>{{ kms }}</span>
    </span>
    <div id="bar"></div>
  </div>
</template>

<script>
export default {
  props: ['xPos', 'kms', 'color', 'textColor', 'label', 'labelColor', 'height'],

  computed: {
    cssVars() {
      return {
        '--xPos': `${this.xPos}px`,
        '--color': this.color,
        '--text-color': this.textColor,
        '--label-color': this.labelColor,
        '--height': this.height ? this.height : '110%',
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

  #cursor {
    position: absolute;
    margin-bottom: 68px;
    bottom: 0;
    height: var(--height);
    display: flex;
    flex-direction: column;
    left: var(--xPos);

    #bar {
      width: 3px;
      background-color: var(--color);
      height: 100%;
      align-self: center;
    }

    #kms {
      color: var(--text-color);
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 1.1em;
      display: flex;

      span {
        background-color: var(--color);
        padding: 4px;
      }

      #label {
        padding: 4px;
        background-color: var(--label-color);
      }
    }
  }
</style>