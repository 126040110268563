<template>
  <v-container>
    <v-row>
      <v-alert :value="createRaceError" transition="fade-transition" type="error" class="alert">
        Impossible de crér la course.
      </v-alert>
    </v-row>

    <v-row class="align-center">
      <v-col
        cols="6"
      >
        <v-row no-gutters class="align-center">
          <v-col cols="1">
            <v-icon
              x-large
              color="primary"
            >
              mdi-plus-box-outline
            </v-icon>
          </v-col>

          <v-col cols="11">
            <h1>Nouvelle course</h1>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="6"
      >
        <v-row
          class="justify-end ml-6"
        >
          <v-btn
            rounded
            outlined
            small
            color="secondary"
            :disabled="!raceEdited"
            @click="handleSave"
          >
            <v-icon left>
              mdi-tray-arrow-down
            </v-icon>
            Sauvegarder
          </v-btn>

          <!-- <v-btn
            rounded
            outlined
            small
            color="red"
            class="ml-3"
          >
            <v-icon left>
              mdi-trash-can-outline
            </v-icon>
            Supprimer
          </v-btn> -->
        </v-row>

      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col
        cols="12"
      >
        <h3>Infos de course</h3>
        <v-divider></v-divider>

        <v-card elevation="1" class="mt-3">
          <v-card-text class="pa-0">
            <v-form>
              <v-container>
                <v-row class="align-center">
                  <v-col cols="4">
                    <!-- "id,name,autocomplete = new-name"
                     force chrome to not autocomplete fields -->
                    <v-text-field
                      label="Nom"
                      id="new-name"
                      name="new-name"
                      autocomplete="new-name"
                      v-model="form.raceName"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      label="Date"
                      v-model="form.raceDate"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      label="Heure"
                      v-model="form.raceTime"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="2">
                    <v-btn
                      rounded
                      outlined
                      small
                      color="secondary"
                      @click="handleSetDeparture"
                    >
                      <v-icon left>
                        mdi-clock-time-five-outline
                      </v-icon>
                      Départ
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row class="align-center mt-1">
                  <v-col cols="4">
                    <v-text-field
                      label="Ville de départ"
                      id="new-name"
                      name="new-name"
                      autocomplete="new-name"
                      v-model="form.raceDepartureCity"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      label="Ville d'arrivée"
                      id="new-name"
                      name="new-name"
                      autocomplete="new-name"
                      v-model="form.raceArrivalCity"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      label="Killomètres"
                      type="number"
                      v-model="form.raceTotalKMs"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="5">
                    <v-text-field
                      label="Infos supplémentaires"
                      v-model="form.raceInfos"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>

      </v-col>

      <v-col
        cols="12"
      >
        <h3>Design</h3>
        <v-divider></v-divider>

        <v-card elevation="1" class="mt-3">
          <v-card-text class="pa-0">
            <v-form>
              <v-container>
                <v-row class="align-center">
                  <v-col cols="4">
                    <v-row class="align-center">
                      <v-col cols="2">
                        <v-sheet
                          :color="form.primaryColor"
                          elevation="1"
                          height="30"
                          width="30"
                        ></v-sheet>
                      </v-col>

                      <v-col cols="8">
                        <v-text-field
                          label="Couleur Primaire"
                          v-model="form.primaryColor"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4">
                    <v-row class="align-center">
                      <v-col cols="2">
                        <v-sheet
                          :color="form.textPrimaryColor"
                          elevation="1"
                          height="30"
                          width="30"
                        ></v-sheet>
                      </v-col>

                      <v-col cols="8">
                        <v-text-field
                          label="Texte Primaire"
                          v-model="form.textPrimaryColor"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4">
                    <v-row class="align-center">
                      <v-col cols="2">
                        <v-sheet
                          :color="form.secondaryColor"
                          elevation="1"
                          height="30"
                          width="30"
                        ></v-sheet>
                      </v-col>

                      <v-col cols="8">
                        <v-text-field
                          label="Couleur Secondaire"
                          v-model="form.secondaryColor"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4">
                    <v-row class="align-center">
                      <v-col cols="2">
                        <v-sheet
                          :color="form.textSecondaryColor"
                          elevation="1"
                          height="30"
                          width="30"
                        ></v-sheet>
                      </v-col>

                      <v-col cols="8">
                        <v-text-field
                          label="Texte Secondaire"
                          v-model="form.textSecondaryColor"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
      >
        <h3>Design Profil</h3>
        <v-divider></v-divider>

        <v-card elevation="1" class="mt-3">
          <v-card-text class="pa-0">
            <v-form>
              <v-container>
                <v-row class="align-center">
                  <v-col cols="4">
                    <v-row class="align-center">
                      <v-col cols="2">
                        <v-sheet
                          :color="form.baseColor"
                          elevation="1"
                          height="30"
                          width="30"
                        ></v-sheet>
                      </v-col>

                      <v-col cols="8">
                        <v-text-field
                          label="Base"
                          v-model="form.baseColor"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4">
                    <v-row class="align-center">
                      <v-col cols="2">
                        <v-sheet
                          :color="form.fillColor"
                          elevation="1"
                          height="30"
                          width="30"
                        ></v-sheet>
                      </v-col>

                      <v-col cols="8">
                        <v-text-field
                          label="Remplissage"
                          v-model="form.fillColor"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4">
                    <v-row class="align-center">
                      <v-col cols="2">
                        <v-sheet
                          :color="form.footerColor"
                          elevation="1"
                          height="30"
                          width="30"
                        ></v-sheet>
                      </v-col>

                      <v-col cols="8">
                        <v-text-field
                          label="Footer"
                          v-model="form.footerColor"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col>
        <h3>Fichiers</h3>
        <v-divider></v-divider>

        <v-row>
          <v-col>
            <v-card elevation="1" class="mt-3">
              <v-card-text class="pa-0">
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col>
                        <v-file-input
                          label="Fichier GPX"
                          counter
                          v-model="form.routeFile"
                        ></v-file-input>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-file-input
                          accept="image/svg+xml"
                          counter
                          label="Fichier SVG"
                          v-model="form.profileFile"
                        ></v-file-input>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-file-input
                          accept="image/png"
                          label="Logo (png)"
                          counter
                          v-model="form.logoFile"
                        ></v-file-input>
                      </v-col>
                    </v-row>

                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>



        <v-row>
          <v-col>
            <h3>URL points de passage</h3>
            <v-divider></v-divider>

            <v-row>
              <v-col>
                <v-card elevation="1" class="mt-3">
                  <v-card-text class="pa-0">
                    <v-form>
                      <v-container>
                        <v-row>
                          <v-col>
                            <v-text-field
                              label="https://docs.google.com/spreadsheets/d/1zl_AEyLpnGXhLeRWXiSejnzUFP5kYErRomGd1PQeuYY/edit#gid=0"
                              single-line
                              v-model="form.crossingPointsURL"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row justify="end">
              <v-col cols="3" class="px-0">
                <p v-if="fetchedCrossingPoints.length" class="font-weight-light text-subtitle-2">
                  {{ fetchedCrossingPoints.length }} points de passages chargé(s)
                </p>
              </v-col>

              <v-col cols="2">
                <v-btn
                  rounded
                  outlined
                  small
                  color="secondary"
                  @click="handleFetchCrossingPoints"
                >
                  <v-icon left>
                    mdi-refresh
                  </v-icon>
                  Refresh
                </v-btn>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import constants from '@/store/constants';
import { mapActions, mapGetters } from 'vuex';
import router from '@/router';
import * as moment from 'moment';

export default {
  data() {
    return {
      raceEdited: true,
      createRaceError: false,

      form: {
        raceName: 'Course',
        raceDate: null,
        raceTime: null,
        raceDepartureCity: 'Doi Inthanon',
        raceArrivalCity: 'Doi Inthanon',
        raceTotalKMs: null,
        raceInfos: null,
  
        primaryColor: '#f42525',
        textPrimaryColor: '#000d44',
        secondaryColor: '#000d44',
        textSecondaryColor: '#ffffff',

        baseColor: '#000d44',
        fillColor: '#f42525',
        footerColor: '#000000',

        crossingPointsURL: null,

        routeFile: null,
        profileFile: null,
        logoFile: null,
      }

    }
  },

  methods: {
    ...mapActions([constants.Actions.CREATE_RACE, constants.Actions.GET_FETCHED_CROSSING_POINTS]),
  
    handleSetDeparture() {
      const currentDate = new Date;

      // force date and time to be in FR format 
      this.form.raceDate = currentDate.toLocaleDateString("fr-FR", {timeZone: "Europe/Paris"});
      this.form.raceTime = currentDate.toLocaleTimeString("fr-FR", {timeZone: "Europe/Paris"});
    },

    async handleSave() {
      // TODO: validation and UI errors
      // TODO: validation for both date and time should be passed

      let startDate = null;
      if (this.form.raceDate && this.form.raceTime) {
        startDate = moment(`${this.form.raceDate} ${this.form.raceTime}`, 'DD/MM/YYYY HH:mm:ss').toDate().toISOString();
      }

      const eventId = router.currentRoute.params.id;

      const filesFormData = new FormData();        
      if (this.form.routeFile) filesFormData.append('route', this.form.routeFile, this.form.routeFile.name);
      if (this.form.profileFile) filesFormData.append('profile', this.form.profileFile, this.form.profileFile.name);
      if (this.form.logoFile) filesFormData.append('logo', this.form.logoFile, this.form.logoFile.name);

      if (this.form.crossingPointsURL === "") this.form.crossingPointsURL = null;

      try {
        await this.createRace({eventId, createRaceDto: {
          name: this.form.raceName,
          startDate,
          departureCity: this.form.raceDepartureCity,
          arrivalCity: this.form.raceArrivalCity,
          infos: this.form.raceInfos,
          totalKMs: this.form.raceTotalKMs !== null ? parseFloat(this.form.raceTotalKMs) : null,
          primaryColor: this.form.primaryColor,
          textPrimaryColor: this.form.textPrimaryColor,
          secondaryColor: this.form.secondaryColor,
          textSecondaryColor: this.form.textSecondaryColor,
          crossingPointsURL: this.form.crossingPointsURL,
          baseColor: this.form.baseColor,
          fillColor: this.form.fillColor,
          footerColor: this.form.footerColor
        }, filesFormData});
      } catch (err) {
        console.log("error", err);
        this.createRaceError = true;
        setTimeout(() => {
          this.createRaceError = false;
        }, 2000);
      }
    },

    async handleFetchCrossingPoints() {
      try {
        await this.fetchCrossingPoints({ crossingPointsURL: this.form.crossingPointsURL });
      } catch(err) {
        console.error('Could not fetch crossing points', err);
      }
    },
  },

  computed: {
    ...mapGetters([constants.Getters.FETCHED_CROSSING_POINTS]),
  },
}
</script>

<style lang="scss" scoped>
  // disables focus on button after clicking on it:
  // https://stackoverflow.com/questions/57830767/is-it-default-for-vuetify-to-keep-active-state-on-buttons-after-click-how-do-yo
  .remove-score-url-btn:focus:before {
    opacity: 0 !important;
  }

  .alert {
    position: fixed;
    top: 10%;
    left: 45%;
    z-index: 100;
  }
</style>