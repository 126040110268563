<template>
  <v-container>
    <v-row>
      <v-alert :value="loadRaceCrossingPointsSuccess" transition="fade-transition" type="success">
        Les points de passage ont étés chargés avec succès.
      </v-alert>
    </v-row>

    <v-row class="align-center">
      <v-col cols="6">
        <v-row no-gutters class="align-center">
          <v-col cols="1">
            <v-icon
              x-large
              color="primary"
            >
              mdi-routes
            </v-icon>
          </v-col>

          <v-col>
            <h1 v-if="selectedRace">Points de passage {{ selectedRace.name }}</h1>
            <h1 v-else>Points de passage</h1>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row class="mt-8">
      <v-col cols="3">
        <v-select
          :items="races"
          item-text="name"
          return-object
          label="Course"
          v-model="selectedRace"
        ></v-select>
      </v-col>
    </v-row>

    <div v-if="selectedRace" class="mt-8">
      <v-row>
        <v-col cols="2">
          <h3>Picto</h3>
        </v-col>

        <v-col cols="4">
          <h3>Nom</h3>
        </v-col>

        <v-col cols="2">
          <h3>KM Début</h3>
        </v-col>

        <v-col cols="2">
          <h3>KM Fin</h3>
        </v-col>

        <v-col>
          <v-row
            class="justify-end ml-6"
          >
            <v-btn
              rounded
              outlined
              small
              color="secondary"
              @click="handleLoadCrossingPoints"
            >
              <v-icon left>
                mdi-refresh
              </v-icon>
              Refresh
            </v-btn>
          </v-row>
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row>
        <v-col>
          <v-card elevation="1" class="mt-3">

            <v-card-text class="pa-0" v-if="selectedRace.crossingPoints">
              <v-form>
                <v-container>

                  <v-row
                    class="align-center"
                    v-for="(crossingPoint, i) in selectedRace.crossingPoints"
                    :key="i"
                  >
                    <v-col cols="2">
                      <v-img
                        :src="crossingPoint.iconURL"
                        max-height="100"
                        max-width="100"
                      ></v-img>
                    </v-col>

                    <v-col cols="4">
                      <v-text-field
                        single-line
                        :value="crossingPoint.name"
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col cols="2">
                      <v-text-field
                        single-line
                        :value="crossingPoint.kmsStart"
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col cols="2">
                      <v-text-field
                        single-line
                        :value="crossingPoint.kmsEnd"
                        readonly
                      ></v-text-field>
                    </v-col>

                  </v-row>

                </v-container>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </div>


  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import constants from '@/store/constants';

export default {
  // retrieved from url parameters thanks to the router route "props: true" property
  props: ["eventId", "raceId"],

  data: () => ({
    loadRaceCrossingPointsSuccess: false,
    selectedRace: null,
  }),

  methods: {
    ...mapActions([
      constants.Actions.GET_ALL_EVENTS_RACES,
      constants.Actions.LOAD_CROSSING_POINTS,
    ]),

    async handleLoadCrossingPoints() {
      try {
        this.selectedRace = await this.loadCrossingPoints({ raceId: this.selectedRace.id });
        this.loadRaceCrossingPointsSuccess = true;
        setTimeout(() => {
          this.loadRaceCrossingPointsSuccess = false;
        }, 2000);
      } catch(err) {
        console.error('Could not load crossing points', err);
        this.loadRaceCrossingPointsSuccess = false;
      }
    },
  },

  computed: {
    ...mapGetters({
      races: constants.Getters.ALL_EVENTS_RACES,
    })
  },

  async created() {
    try {
      this.getAllEventsRaces();
    } catch(err) {
      console.error('Could not fetch events races', err);
    }
  },
}
</script>
