<template>
  <v-container>
    <v-row justify="center">
      <v-col v-if="deleteRaceError" sm="12" md="8">
        <v-alert :value="deleteRaceError" transition="fade-transition" type="error" class="alert">
          Impossible de supprimer la course<span v-if="deleteRaceErrorMsg" class="font-weight-bold">: {{ deleteRaceErrorMsg }}</span>
        </v-alert>
      </v-col>

      <v-col v-if="updateRaceError" sm="12" md="8">
        <v-alert :value="updateRaceError" transition="fade-transition" type="error" class="alert">
          Impossible de mettre à jour la course <span v-if="updateRaceErrorMsg" class="font-weight-bold">: {{ updateRaceErrorMsg }}</span>
        </v-alert>
      </v-col>

      <v-col v-if="updateRaceSuccess" sm="12" md="8">
        <v-alert :value="updateRaceSuccess" transition="fade-transition" type="success" class="alert">
          La course a été mise à jour.
        </v-alert>
      </v-col>

      <v-col v-if="loadRaceCrossingPointsSuccess" sm="12" md="8">
        <v-alert :value="loadRaceCrossingPointsSuccess" transition="fade-transition" type="success" class="alert">
          Les points de passage ont étés chargés avec succès.
        </v-alert>
      </v-col>
    </v-row>

    <v-row v-if="deleteRaceDialog" class="align-center">
      <v-col>
        <v-dialog
          v-model="deleteRaceDialog"
          max-width="600px"
        >
          <v-card>
            <v-card-title class="text-h7">
              Êtes-vous sûr de vouloir supprimer cette course ?
            </v-card-title>
            <v-card-subtitle class="mt-3">
              Cela supprimera également les Lives liés à cette course.
            </v-card-subtitle>
  
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="red darken-1"
                text
                @click="deleteRaceDialog = false"
              >
                Annuler
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="handleDelete"
              >
                Supprimer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>

    <v-row class="align-center" v-if="race">
      <v-col
        cols="6"
      >
        <v-row no-gutters class="align-center">
          <v-col cols="1">
            <v-img
              v-if="race.logoFileURL"
              :src="race.logoFileURL"
              max-height="50"
              max-width="50"
            ></v-img>

            <v-icon
              v-else
              x-large
              color="primary"
            >
              mdi-tune
            </v-icon>
          </v-col>

          <v-col cols="11">
            <h1 class="ml-3">{{ race.name }}</h1>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="6"
      >
        <v-row
          class="justify-end ml-6"
        >
          <v-btn
            rounded
            outlined
            small
            color="secondary"
            @click="handleSave"
          >
            <v-icon left>
              mdi-tray-arrow-down
            </v-icon>
            Sauvegarder
          </v-btn>

          <v-btn
            rounded
            outlined
            small
            color="red"
            class="ml-3"
            @click="deleteRaceDialog = true"
          >
            <v-icon left>
              mdi-trash-can-outline
            </v-icon>
            Supprimer
          </v-btn>
        </v-row>

      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col cols="12">
        <h3>Infos de course</h3>
        <v-divider></v-divider>

        <v-card elevation="1" class="mt-3">
          <v-card-text class="pa-0">
            <v-form>
              <v-container>
                <v-row class="align-center">
                  <v-col cols="4">
                    <!-- "id,name,autocomplete = new-name"
                     force chrome to not autocomplete fields -->
                    <v-text-field
                      label="Nom"
                      id="new-name"
                      name="new-name"
                      autocomplete="new-name"
                      v-model="race.name"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      label="Date"
                      v-model="form.raceDate"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      label="Heure"
                      v-model="form.raceTime"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="2">
                    <v-btn
                      rounded
                      outlined
                      small
                      color="secondary"
                      @click="handleSetDeparture"
                    >
                      <v-icon left>
                        mdi-clock-time-five-outline
                      </v-icon>
                      Départ
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row class="align-center mt-1">
                  <v-col cols="4">
                    <v-text-field
                      label="Ville de départ"
                      id="new-name"
                      name="new-name"
                      autocomplete="new-name"
                      v-model="race.departureCity"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      label="Ville d'arrivée"
                      id="new-name"
                      name="new-name"
                      autocomplete="new-name"
                      v-model="race.arrivalCity"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="3">
                    <v-text-field
                      label="Killomètres"
                      type="number"
                      v-model="race.totalKMs"
                    ></v-text-field>
                  </v-col>

                  <v-col cols="5">
                    <v-text-field
                      label="Infos supplémentaires"
                      v-model="race.infos"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>

      </v-col>

      <v-col cols="12"
      >
        <h3>Design</h3>
        <v-divider></v-divider>

        <v-card elevation="1" class="mt-3">
          <v-card-text class="pa-0">
            <v-form>
              <v-container>
                <v-row class="align-center">
                  <v-col cols="4">
                    <v-row class="align-center">
                      <v-col cols="2">
                        <v-sheet
                          :color="race.primaryColor"
                          elevation="1"
                          height="30"
                          width="30"
                        ></v-sheet>
                      </v-col>

                      <v-col cols="8">
                        <v-text-field
                          label="Couleur Primaire"
                          v-model="race.primaryColor"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4">
                    <v-row class="align-center">
                      <v-col cols="2">
                        <v-sheet
                          :color="race.textPrimaryColor"
                          elevation="1"
                          height="30"
                          width="30"
                        ></v-sheet>
                      </v-col>

                      <v-col cols="8">
                        <v-text-field
                          label="Texte Primaire"
                          v-model="race.textPrimaryColor"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4">
                    <v-row class="align-center">
                      <v-col cols="2">
                        <v-sheet
                          :color="race.secondaryColor"
                          elevation="1"
                          height="30"
                          width="30"
                        ></v-sheet>
                      </v-col>

                      <v-col cols="8">
                        <v-text-field
                          label="Couleur Secondaire"
                          v-model="race.secondaryColor"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4">
                    <v-row class="align-center">
                      <v-col cols="2">
                        <v-sheet
                          :color="race.textSecondaryColor"
                          elevation="1"
                          height="30"
                          width="30"
                        ></v-sheet>
                      </v-col>

                      <v-col cols="8">
                        <v-text-field
                          label="Texte Secondaire"
                          v-model="race.textSecondaryColor"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12"
      >
        <h3>Design Profil</h3>
        <v-divider></v-divider>

        <v-card elevation="1" class="mt-3">
          <v-card-text class="pa-0">
            <v-form>
              <v-container>
                <v-row class="align-center">
                  <v-col cols="4">
                    <v-row class="align-center">
                      <v-col cols="2">
                        <v-sheet
                          :color="race.baseColor"
                          elevation="1"
                          height="30"
                          width="30"
                        ></v-sheet>
                      </v-col>

                      <v-col cols="8">
                        <v-text-field
                          label="Base"
                          v-model="race.baseColor"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4">
                    <v-row class="align-center">
                      <v-col cols="2">
                        <v-sheet
                          :color="race.fillColor"
                          elevation="1"
                          height="30"
                          width="30"
                        ></v-sheet>
                      </v-col>

                      <v-col cols="8">
                        <v-text-field
                          label="Remplissage"
                          v-model="race.fillColor"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="4">
                    <v-row class="align-center">
                      <v-col cols="2">
                        <v-sheet
                          :color="race.footerColor"
                          elevation="1"
                          height="30"
                          width="30"
                        ></v-sheet>
                      </v-col>

                      <v-col cols="8">
                        <v-text-field
                          label="Footer"
                          v-model="race.footerColor"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-5">
      <v-col>
        <h3>Fichiers</h3>
        <v-divider></v-divider>

        <v-row>
          <v-col>
            <v-card elevation="1" class="mt-3">
              <v-card-text class="pa-0">
                <v-form>
                  <v-container>
                    <template>
                      <v-row v-if="race.routeFileURL" class="align-baseline justify-space-around">
                        <v-col cols="7">
                          <a
                            :href="race.routeFileURL"
                            class="text-decoration-underline blue--text"
                            target="_blank"
                          >{{ race.routeFileURL }}</a>
                        </v-col>

                        <v-col>
                          <v-file-input
                            label="Remplacer GPX"
                            counter
                            v-model="form.routeFile"
                          ></v-file-input>
                        </v-col>
                      </v-row>

                      <v-row v-else>
                        <v-col>
                          <v-file-input
                            label="Fichier GPX"
                            counter
                            v-model="form.routeFile"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </template>

                    <template>
                      <v-row v-if="race.profileFileURL" class="align-baseline justify-space-around">
                        <v-col cols="7">
                          <a
                            :href="race.profileFileURL"
                            class="text-decoration-underline blue--text"
                            target="_blank"
                          >{{ race.profileFileURL }}</a>
                        </v-col>

                        <v-col>
                          <v-file-input
                            accept="image/svg+xml"
                            label="Remplacer SVG"
                            counter
                            v-model="form.profileFile"
                          ></v-file-input>
                        </v-col>
                      </v-row>

                      <v-row v-else>
                        <v-col>
                          <v-file-input
                            accept="image/svg+xml"
                            label="Fichier SVG"
                            counter
                            v-model="form.profileFile"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </template>

                    <template>
                      <v-row v-if="race.logoFileURL" class="align-baseline justify-space-around">
                        <v-col cols="7">
                          <a
                            :href="race.logoFileURL"
                            class="text-decoration-underline blue--text"
                            target="_blank"
                          >{{ race.logoFileURL }}</a>
                        </v-col>

                        <v-col>
                          <v-file-input
                            accept="image/png"
                            label="Remplacer Logo"
                            counter
                            v-model="form.logoFile"
                          ></v-file-input>
                        </v-col>
                      </v-row>

                      <v-row v-else>
                        <v-col>
                          <v-file-input
                            accept="image/png"
                            label="Logo (png)"
                            counter
                            v-model="form.logoFile"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </template>

                    <!-- <v-row>
                      <v-col>
                        <v-file-input
                          label="Scoreboard PNG"
                        ></v-file-input>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-file-input
                          label="Habillage antenne (PNG ou vidéo)"
                        ></v-file-input>
                      </v-col>
                    </v-row> -->

                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <h3>URL points de passage</h3>
            <v-divider></v-divider>

            <v-row>
              <v-col>
                <v-card elevation="1" class="mt-3">
                  <v-card-text class="pa-0">
                    <v-form>
                      <v-container>
                        <v-row>
                          <v-col>
                            <v-text-field
                              label="https://docs.google.com/spreadsheets/d/1zl_AEyLpnGXhLeRWXiSejnzUFP5kYErRomGd1PQeuYY/edit#gid=0"
                              single-line
                              v-model="race.crossingPointsURL"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                      </v-container>
                    </v-form>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row justify="end">
              <v-col cols="3" class="px-0">
                <p v-if="fetchedCrossingPoints.length" class="font-weight-light text-subtitle-2">
                  {{ fetchedCrossingPoints.length }} points de passages chargé(s)
                </p>
              </v-col>

              <v-col cols="2">
                <v-btn
                  rounded
                  outlined
                  small
                  color="secondary"
                  @click="handleLoadCrossingPoints"
                >
                  <v-icon left>
                    mdi-refresh
                  </v-icon>
                  Refresh
                </v-btn>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import constants from '@/store/constants';
import * as moment from 'moment';

export default {
  // retrieved from url parameters thanks to the router route "props: true" property
  props: ["eventId", "raceId"],

  data() {
    return {
      raceEdited: false,
      updateRaceError: false,
      updateRaceErrorMsg: null,
      deleteRaceError: false,
      deleteRaceErrorMsg: null,
      updateRaceSuccess: false,
      loadRaceCrossingPointsSuccess: false,
      deleteRaceDialog: false,

      form: {
        routeFile: null,
        profileFile: null,
        logoFile: null,
        raceDate: null,
        raceTime: null
      },
    }
  },

  methods: {
    ...mapActions([
      constants.Actions.GET_RACE,
      constants.Actions.GET_FETCHED_CROSSING_POINTS,
      constants.Actions.LOAD_CROSSING_POINTS,
      constants.Actions.UPDATE_RACE,
      constants.Actions.DELETE_RACE,
    ]),

    handleSetDeparture() {
      const currentDate = new Date();

      // force date and time to be in FR format 
      this.form.raceDate = currentDate.toLocaleDateString("fr-FR", {timeZone: "Europe/Paris"});
      this.form.raceTime = currentDate.toLocaleTimeString("fr-FR", {timeZone: "Europe/Paris"});
    },

    async handleSave() {
      let startDate = null;
      if (this.form.raceDate && this.form.raceTime) {
        startDate = moment(`${this.form.raceDate} ${this.form.raceTime}`, 'DD/MM/YYYY HH:mm:ss').toDate().toISOString();
      }

      if (this.race.crossingPointsURL === "") this.race.crossingPointsURL = null;

      const updatedRace = Object.assign({}, this.race);
      updatedRace.totalKMs = parseFloat(updatedRace.totalKMs);
      updatedRace.startDate = startDate;
      delete updatedRace.id;
      delete updatedRace.routeFileURL;
      delete updatedRace.profileFileURL;
      delete updatedRace.logoFileURL;
      delete updatedRace.elapsedTime;
      delete updatedRace.crossingPoints;

      const filesFormData = new FormData();
      if (this.form.routeFile) {
        console.log('route file present');
        filesFormData.append('route', this.form.routeFile, this.form.routeFile.name);
      }
      if (this.form.profileFile) filesFormData.append('profile', this.form.profileFile, this.form.profileFile.name);
      if (this.form.logoFile) filesFormData.append('logo', this.form.logoFile, this.form.logoFile.name);

      try {
        await this.updateRace({ eventId: this.eventId, raceId: this.raceId, updateRaceDto: updatedRace, filesFormData});
        this.form.routeFile = null;
        this.form.profileFile = null;
        this.form.logoFile = null;
        this.updateRaceSuccess = true;
        setTimeout(() => {
          this.updateRaceSuccess = false;
        }, 2000);
      } catch(err) {
        console.error('Could not update race', err.response.data.message);
        this.updateRaceError = true;
        this.updateRaceErrorMsg = err.response.data.message;

        setTimeout(() => {
          this.updateRaceError = false;
        }, 10000);
      }
    },

    async handleDelete() {
      try {
        await this.deleteRace({ eventId: this.eventId, raceId: this.raceId });
      } catch(err) {
        console.error('Could not delete race', err);
        this.deleteRaceErrorMsg = "Vérifiez que la course ne soit pas référencée dans un Live."
        this.deleteRaceDialog = false;
        this.deleteRaceError = true;

        setTimeout(() => {
          this.deleteRaceError = false;
        }, 5000);
      }
    },

    async handleLoadCrossingPoints() {
      try {
        await this.loadCrossingPoints({ raceId: this.raceId });
        this.loadRaceCrossingPointsSuccess = true;
        setTimeout(() => {
          this.loadRaceCrossingPointsSuccess = false;
        }, 2000);
      } catch(err) {
        console.error('Could not load crossing points', err);
        this.loadRaceCrossingPointsSuccess = false;
      }
    },
  },

  computed: {
    ...mapGetters([constants.Getters.RACE]),
    ...mapGetters([constants.Getters.FETCHED_CROSSING_POINTS]),
  },

  async created() {
    this.$watch(
      // watch the params of the route to fetch the data again when user wants to display another race
      () => this.$route.params,
      async () => {
        try {
          await this.getRace({ eventId: this.eventId, raceId: this.raceId });

          if (this.race.startDate) {
            const raceStartDate = new Date(this.race.startDate);
            this.form.raceDate = raceStartDate.toLocaleDateString("fr-FR", {timeZone: "Europe/Paris"});
            this.form.raceTime = raceStartDate.toLocaleTimeString("fr-FR", {timeZone: "Europe/Paris"});
          }

          this.form.routeFile = null;
          this.form.profileFile = null;
        } catch(err) {
          console.error('Could not fetch race', err);
        }
      },
      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true }
    )
  }
}
</script>

<style lang="scss" scoped>
  .alert {
    position: fixed;
    top: 10%;
    left: 45%;
    z-index: 100;

    &.error {
      left: 30%;
    }
  }
</style>