<template>
  <div id="footer-container" :style="cssVars">
  <div class="start">
    <v-icon
      :color="textColor"
      dark
    >
      mdi-flag
    </v-icon>
    <p>{{ departureText }}</p>
  </div>

  <div class="infos">
    {{ infosText }}
  </div>

  <div class="finish">
    <v-icon
      :color="textColor"
      dark
    >
      mdi-flag-checkered
    </v-icon>
    <p>{{ arrivalText }}</p>
  </div>
</div>
</template>

<script>
export default {
  props: ['color', 'textColor', 'departureText', 'infosText', 'arrivalText'],

  computed: {
    cssVars() {
      return {
        '--color': this.color,
        '--text-color': this.textColor,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  #footer-container {
    display: flex;
    justify-content: space-between;
    margin: -4px 0 0 0;
    background-color: var(--color);
    color: var(--text-color);
    padding: 10px;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 1.1em;
    align-items: center;
    // width of container (profile) + width of arrival cursor bar
    width: 100.09%;

    .start, .finish {
      display: flex;
      align-items: center;

      p {
        margin-left: 10px;
      }
    }
  }
</style>