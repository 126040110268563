import { io } from "socket.io-client";
import config from "@/config";

const webSocketClient = io(config.API_HOST, {
  transportOptions: {
    polling: {
      extraHeaders: {
        Authorization: config.WS_TOKEN
      }
    }
  }
});

export default webSocketClient;
