import client from "./client";

interface UserLoginDto {
  email: string;
  password: string;
}

type CreateRaceDto = {
  name: string;
  startDate: Date;
  departureCity: string;
  arrivalCity: string;
  infos: string;
  totalKMs: number;
  primaryColor: string;
  textPrimaryColor: string;
  secondaryColor: string;
  textSecondaryColor: string;
  crossingPointsURL: string;
  baseColor: string;
  fillColor: string;
  footerColor: string;
}

export type UpdateRaceDto = CreateRaceDto;

export type CreateTeamDto = {
  name: string;
}

export type CreateRoleDto = {
  name: string;
  iconURL: string;
}

export type CreateLiveStreamDto = {
  // references are ids here
  team: string;
  race: string;
  role: string;
}

export type UpdateLiveStreamDto = CreateLiveStreamDto & {
  gpsFilters: any;
};

type TeamWithLabel = {
  team: string;
  label: string;
}

export type CreateIntervalDto = {
  teamReference: TeamWithLabel;
  intervals: TeamWithLabel[];
}

export type UpdateIntervalDto = CreateIntervalDto;

class Repository {
  static login(user: UserLoginDto) {
    return client.post("/auth/login", user);
  }

  static logout() {
    return client.post("/auth/logout");
  }

  static getEvent(eventId: string) {
    return client.get(`/events/${eventId}`);
  }

  static createEvent(name: string) {
    return client.post('/events', { name });
  }

  static deleteEvent(eventId: string) {
    return client.delete(`/events/${eventId}`);
  }

  static getAllEvents() {
    return client.get(`/events`);
  }

  static createRace(eventId: string, createRaceDto: CreateRaceDto) {
    return client.post(`/events/${eventId}/races`, createRaceDto);
  }

  static uploadFilesToRace(eventId: string, raceId: string, filesFormData: FormData) {
    return client.post(`/events/${eventId}/races/${raceId}/upload`, filesFormData);
  }

  static getRace(eventId: string, raceId: string) {
    return client.get(`/events/${eventId}/races/${raceId}`);
  }

  static updateRace(eventId: string, raceId: string, updateRaceDto: UpdateRaceDto) {
    return client.put(`/events/${eventId}/races/${raceId}`, updateRaceDto);
  }

  static deleteRace(eventId: string, raceId: string) {
    return client.delete(`/events/${eventId}/races/${raceId}`);
  }

  // used for races not created yet
  static fetchCrossingPoints(crossingPointsURL: string) {
    return client.get(`/events/races/crossingPoints`, { params: { crossingPointsURL } });
  }

  // load for existing race
  static loadCrossingPoints(raceId: string) {
    return client.post(`/events/races/${raceId}/crossingPoints`);
  }

  static getAllTeams() {
    return client.get(`/teams`);
  }

  static getAllTeamsNotReferencedByLiveStreams() {
    return client.get(`/teams?notReferencedByLiveStreams=true`);
  }

  static createTeam(team: CreateTeamDto) {
    return client.post(`/teams`, team);
  }

  static deleteTeam(teamId: string) {
    return client.delete(`/teams/${teamId}`);
  }

  static getAllRoles() {
    return client.get(`/roles`);
  }

  static createRole(role: CreateRoleDto) {
    return client.post(`/roles`, role);
  }

  static deleteRole(roleId: string) {
    return client.delete(`/roles/${roleId}`);
  }

  static getLiveStream(liveStreamId: string) {
    return client.get(`/livestreams/${liveStreamId}`);
  }

  static getAllLiveStreams() {
    return client.get(`/livestreams`);
  }

  static createLiveStream(liveStreamDto: CreateLiveStreamDto) {
    return client.post(`/livestreams`, liveStreamDto);
  }

  static updateLiveStream(liveStreamId: string, liveStreamDto: UpdateRaceDto) {
    return client.put(`/livestreams/${liveStreamId}`, liveStreamDto);
  }

  static deleteLiveStream(liveStreamId: string) {
    return client.delete(`/livestreams/${liveStreamId}`);
  }

  static getInterval(intervalId: string) {
    return client.get(`/intervals/${intervalId}`);
  }

  static getAllIntervals() {
    return client.get(`/intervals`);
  }

  static createInterval(intervalDto: CreateIntervalDto) {
    return client.post(`/intervals`, intervalDto);
  }

  static updateInterval(intervalId: string, intervalDto: UpdateIntervalDto) {
    console.log("intervalDto", intervalDto);
    
    return client.put(`/intervals/${intervalId}`, intervalDto);
  }

  static deleteInterval(intervalId: string) {
    return client.delete(`/intervals/${intervalId}`);
  }

  static async validateServiceUserSecret(secret: string): Promise<boolean> {
    const res = await client.post('/auth/validateServiceUserSecret', {}, { headers: { 'Authorization': `Basic ${secret}` } });
    return res.status === 200;
  }
}

export default Repository;
