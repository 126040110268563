<template>
  <v-container>
    <v-row>
      <v-alert :value="createLiveStreamError" transition="fade-transition" type="error" class="alert">
        Impossible de créer le live. Veuillez vérifier que la course ait un fichier GPX et que les filtres soient définis correctement.
      </v-alert>

      <v-alert :value="updateLiveStreamError" transition="fade-transition" type="error" class="alert">
        Impossible de modifier le live. Veuillez vérifier que la course ait un fichier GPX et que les filtres soient définis correctement.
      </v-alert>

      <v-alert :value="updateLiveStreamSuccess" transition="fade-transition" type="success" class="alert">
        Le Live a été mise à jour.
      </v-alert>

      <v-alert :value="createLiveStreamSuccess" transition="fade-transition" type="success" class="alert">
        Le Live a été crée avec succès.
      </v-alert>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-row no-gutters class="align-center">
          <v-col cols="1">
            <v-icon
              x-large
              color="primary"
            >
              mdi-radio-tower
            </v-icon>
          </v-col>

          <v-col>
            <h1>Live</h1>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

        <v-row
          v-for="(liveStream, i) in transformLivestreams(liveStreams)"
          :key="i"
        >
          <v-col>
            <v-card :elevation="getCardElevation(liveStream.id)" class="mt-3">

              <v-card-text class="pa-0" v-if="liveStreams">
                <v-form>
                  <v-container class="lv-container" :id="[liveStream.id ? 'lv-container-' + liveStream.id : '']">

                    <v-row
                      class="align-center"
                    >
                      <v-col cols="8">
                        <v-row>
                          <v-col class="text-center">
                            <v-row class="flex-column" v-if="liveStream.id">
                              <v-col>
                                <span class="text-caption">Statut envoie des données:</span>
                              </v-col>

                              <v-col>
                                <div class="pa-2 status-circle rounded-circle d-inline-block" :id="[liveStream.id ? 'status-circle-' + liveStream.id : '']"></div>
                              </v-col>
                            </v-row>
                          </v-col>

                          <v-col>
                            <v-text-field
                              v-if="liveStream.id"
                              label="Équipe"
                              :readonly="liveStream.team.id !== null"
                              v-model="liveStream.team.name"
                            ></v-text-field>

                            <v-select
                              v-else
                              v-model="liveStream.team"
                              :items="teamsNotReferencedByLiveStreams"
                              item-text="name"
                              item-value="id"
                              label="Équipe"
                              persistent-hint
                              single-line
                              return-object
                            ></v-select>
                          </v-col>

                          <v-col>
                            <v-select
                              v-model="liveStream.race"
                              :items="races"
                              item-text="name"
                              item-value="id"
                              label="Course"
                              persistent-hint
                              return-object
                              :disabled="liveStream.isLocked"
                              @input="toggleEditing(true, liveStream.id, i)"
                            ></v-select>
                          </v-col>

                          <v-col>
                            <v-select
                              v-model="liveStream.role"
                              :items="roles"
                              item-text="name"
                              item-value="id"
                              label="Rôle"
                              persistent-hint
                              return-object
                              @input="toggleEditing(true, liveStream.id, i)"
                            ></v-select>
                          </v-col>
                        </v-row>

                      </v-col>

                      <v-col>
                        <v-row align="center" justify="end">
                          <div v-if="editContainers.includes(liveStream.id)">
                            <v-col cols="6" xl="4">
                                <v-btn
                                  rounded
                                  outlined
                                  small
                                  :disabled="!liveStream.team || !liveStream.race || !liveStream.role"
                                  color="secondary"
                                  @click="handleSaveLiveStream(i)"
                                >
                                  <v-icon left>
                                    mdi-pencil-outline
                                  </v-icon>
                                  Sauvegarde
                                </v-btn>
                            </v-col>

                            <v-col cols="6" xl="4" class="mr-10 ml-5">
                              <v-row class="justify-end ml-6">
                                <v-btn
                                  rounded
                                  outlined
                                  small
                                  color="red"
                                  class="remove-btn"
                                  @click.prevent="handleCancelChanges(liveStream.id, i)"
                                >
                                  <v-icon dark left>
                                    mdi-close
                                  </v-icon>
                                  Annuler
                                </v-btn>
                              </v-row>
                            </v-col>
                          </div>
                          <div v-else>
                            <v-col cols="6" xl="4" v-if="!liveStream.id">
                                <v-btn
                                  rounded
                                  outlined
                                  small
                                  :disabled="!liveStream.team || !liveStream.race || !liveStream.role"
                                  color="secondary"
                                  @click="handleSaveLiveStream(i)"
                                >
                                  <v-icon left>
                                    mdi-plus-circle-outline
                                  </v-icon>
                                  "Créer"
                                </v-btn>
                            </v-col>
                            <div v-else>
                                <div class="d-inline-flex align-center ml-3 mt-2">
                                  <div class="mr-2">Verrouiller</div>
                                  <v-switch
                                    v-model=liveStream.isLocked
                                    @change="handleSaveLiveStream(i, true)"
                                    color="success"
                                  ></v-switch>
                                </div>
                            </div>

                            <v-col cols="6" xl="4" class="mr-10 ml-5">
                              <v-row class="justify-end ml-6">
                                <v-btn
                                  rounded
                                  outlined
                                  small
                                  color="red"
                                  class="remove-btn"
                                  @click.prevent="handleDeleteLiveStreamClick(i)"
                                >
                                  <v-icon dark left>
                                    mdi-trash-can-outline
                                  </v-icon>
                                  Supprimer
                                </v-btn>
                              </v-row>
                            </v-col>
                          </div>
                        </v-row>
                      </v-col>
                    </v-row>

                    <v-row>
                            <v-col cols="3">
                              <v-text-field
                                v-if="liveStream.id"
                                clearable
                                label="Filtre: Supérieur à (KMs)"
                                type="number"
                                v-model.number="liveStream.gpsFilters.gte"
                                @input="toggleEditing(true, liveStream.id, i)"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="3">
                              <v-text-field
                                v-if="liveStream.id"
                                label="Filtre: Inférieur à (KMs)"
                                clearable
                                type="number"
                                v-model.number="liveStream.gpsFilters.lte"
                                @input="toggleEditing(true, liveStream.id, i)"
                              ></v-text-field>
                            </v-col>

                            <v-col cols="2">
                              <v-text-field
                                v-if="liveStream.id"
                                label="Délai (secondes)"
                                clearable
                                type="number"
                                v-model.number="liveStream.delay"
                                @input="toggleEditing(true, liveStream.id, i)"
                              ></v-text-field>
                            </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                              <v-text-field
                                v-if="liveStream.id"
                                label="Web URL"
                                readonly
                                :value="getHTMLOutputURL(liveStream)"
                              ></v-text-field>
                            </v-col>

                            <v-col>
                              <v-text-field
                                v-if="liveStream.id"
                                label="JSON URL"
                                readonly
                                :value="getJSONOutputURL(liveStream)"
                              ></v-text-field>
                            </v-col>
                    </v-row>

                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-2" justify="end">
          <v-col cols="2">
            <v-btn
              rounded
              outlined
              small
              color="secondary"
              @click="handleAddLiveStream"
            >
              <v-icon left>
                mdi-plus-circle-outline
              </v-icon>
              Nouveau Live
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="align-center">
          <v-dialog
            v-model="deleteLiveStreamDialog"
            max-width="600px"
          >

            <v-card>
              <v-card-title class="text-h7">
                Êtes-vous sûr de vouloir supprimer ce Live ?
              </v-card-title>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  text
                  @click="deleteLiveStreamDialog = false"
                >
                  Annuler
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="handleDeleteLiveStream(deleteSelectedLiveStreamIndex)"
                >
                  Supprimer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>

      <!-- </v-col> -->
  </v-container>
</template>

<script>
import constants from '@/store/constants';
import { mapActions, mapGetters } from 'vuex';
import hostame from '@/utils/hostname';
import config from "@/config";
import WebSocketRepository from '@/api/webSockets/WebSocketRepository';
const wsRepository = new WebSocketRepository();

export default {
  data() {
    return {
      updateLiveStreamSuccess: false,
      createLiveStreamSuccess: false,
      createLiveStreamError: false,
      updateLiveStreamError: false,
      deleteSelectedLiveStreamIndex: null,
      deleteLiveStreamDialog: false,
      fetchDataTicker: null,
      activeTimeoutMs: 10000,
      editContainers: [],
    }
  },

  mounted() {
    document.addEventListener("keydown", this.handleEscKey);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleEscKey);
  },

  methods: {
    ...mapActions([
      constants.Actions.GET_ALL_LIVE_STREAMS,
      constants.Actions.RESET_LIVE_STREAM,
      constants.Actions.CREATE_LIVE_STREAM,
      constants.Actions.UPDATE_LIVE_STREAM,
      constants.Actions.DELETE_LIVE_STREAM,

      constants.Actions.GET_ALL_EVENTS_RACES,

      constants.Actions.GET_ALL_ROLES,
      constants.Actions.GET_ALL_TEAMS,
      constants.Actions.GET_ALL_TEAMS_NOT_REFERENCED_BY_LIVESTREAMS,
    ]),

    getHTMLOutputURL(liveStream) {
      // join baseURL + path to automatically resolve '/' issues
      // e.g if baseURL has a '/' at the end and path has also a '/' in front: avoids having '//'
      return new URL(`/live/${liveStream.pageIndex}`, hostame()).href;
    },

    getJSONOutputURL(liveStream) {
      return new URL(`/liveStreams/${liveStream.id}`, config.API_HOST).href;
    },

    handleEscKey(event) {
      if (event.key === "Escape" && this.editContainers.length > 0) {
        this.editContainers.forEach(editContainer => {
          this.toggleEditing(false, editContainer, -1, true);
        });
        this.getAllLiveStreams();
      }
    },

    handleAddLiveStream() {
      const defaultLiveStreamItem = {
        id: null,
        pageIndex: null,
        race: null,
        role: null,
        team: null,
      };

      this.liveStreams.push(defaultLiveStreamItem);
    },

    async toggleEditing(value, liveStreamId, itemIndex, multiple = false) {
      const container = document.querySelector(`#lv-container-${liveStreamId}`);
      if (!container) throw new Error(`No container element with id ${container}`);

      if (value) {
        container.classList.add('active');
        if (!this.editContainers.includes(liveStreamId)) {
          this.editContainers.push(liveStreamId);
        }
      } else {
        container.classList.remove('active');
        this.editContainers = this.editContainers.filter(liveStream => liveStream !== liveStreamId);
        if (!multiple) {
          await this.resetLiveStream({ liveStreamId: liveStreamId, index: itemIndex });
        }
      }
    },

    getCardElevation(liveStreamId) {
      return this.editContainers.includes(liveStreamId) ? 0 : 1;
    },

    handleCancelChanges(liveStreamId, itemIndex) {
      this.toggleEditing(false, liveStreamId, itemIndex);
    },

    handleDeleteLiveStreamClick(itemIndex) {
      const liveStream = this.liveStreams[itemIndex];
      if (!liveStream) return;

      // if LiveStream from api then show a confirmation dialog
      if (liveStream.id) {
        this.deleteSelectedLiveStreamIndex = itemIndex;
        this.deleteLiveStreamDialog = true;
      } else {
        this.handleDeleteLiveStream(itemIndex);
      }
    },

    transformLivestreams(liveStreams) {
      return liveStreams.map(liveStream => {
        if (liveStream.gpsFilters === null) {
          liveStream.gpsFilters = {
            gte: null,
            lte: null
          }
        }
        return liveStream;
      });
    },

    async handleSaveLiveStream(itemIndex, isLockUpdate = false) {
      const liveStream = this.liveStreams[itemIndex];
      if (!liveStream) return;

      // liveStream has id so it's from api so update it
      if (liveStream.id) {
        try {
          await this.updateLiveStream({
            liveStreamId: liveStream.id,
            updateLiveStreamDto: {
              race: liveStream.race.id,
              role: liveStream.role.id,
              team: liveStream.team.id,
              delay: liveStream.delay,
              gpsFilters: (liveStream.gpsFilters.gte === null && liveStream.gpsFilters.lte === null)
                ? undefined : liveStream.gpsFilters,  // remove gpsFilters if both values are null so it's not taken into account by the back
              isLocked: liveStream.isLocked,
            },
          });

          if (!isLockUpdate) {
            this.updateLiveStreamSuccess = true;
            setTimeout(() => {
              this.updateLiveStreamSuccess = false;
            }, 2000);
          }
        } catch(err) {
          console.error("update liveStream error", err);
          this.updateLiveStreamError = true;
          setTimeout(() => {
            this.updateLiveStreamError = false;
          }, 2000);
        }
        this.toggleEditing(false, liveStream.id, itemIndex);
      } else {
        try {
          await this.createLiveStream({
            createLiveStreamDto: {
              race: liveStream.race.id,
              role: liveStream.role.id,
              team: liveStream.team.id,
            },
          });

          this.createLiveStreamSuccess = true;
          setTimeout(() => {
            this.createLiveStreamSuccess = false;
          }, 2000);
        } catch(err) {
          console.error("create liveStream error", err);
          this.liveStreams.pop(itemIndex);
          this.createLiveStreamError = true;
          setTimeout(() => {
            this.createLiveStreamError = false;
          }, 2000);
        }
      }
    },

    handleDeleteLiveStream(itemIndex) {
      const liveStreamToRemove = this.liveStreams[itemIndex];
      if (!liveStreamToRemove) return;

      this.deleteSelectedLiveStreamIndex = null;
      this.deleteLiveStreamDialog = false;

      // it's a liveStream obj from the API and not one created locally
      if (liveStreamToRemove.id) {
        try {
          this.deleteLiveStream({
            liveStreamId: liveStreamToRemove.id,
          });
        } catch(err) {
          console.error("delete liveStream from api error", err);
        }
      }
      this.liveStreams.splice(itemIndex, 1);
    },

    async registerWsHandlers() {
      wsRepository.registerHandler('getLiveStreams', async(data) => await this.handleGetLiveStreamResp(data));
    },

    async handleGetLiveStreamResp(data) {
      for (const liveStream of data) {
        if (liveStream.delay === null) {
          if ((Date.now() - liveStream.gps.timestamp) < this.activeTimeoutMs) {
            this.setLiveStreamActive(liveStream.id, true);
          } else {
            this.setLiveStreamActive(liveStream.id, false);
          }
        }
        else {
          if (((Date.now() - liveStream.delay * 1000) - liveStream.gps.timestamp) < this.activeTimeoutMs) {
            this.setLiveStreamActive(liveStream.id, true);
          } else {
            this.setLiveStreamActive(liveStream.id, false);
          }
        }

        if (!this.editContainers.includes(liveStream.id)) {
          // apply role, race and filters updates possible on mobile side
          const stateLiveStreamToUpdate = this.liveStreams.filter(stateLiveStream => stateLiveStream.id === liveStream.id).pop();
          stateLiveStreamToUpdate.role = liveStream.role;
          if (!liveStream.isLocked) {
            stateLiveStreamToUpdate.race = liveStream.race;
          }
          stateLiveStreamToUpdate.gpsFilters = liveStream.gpsFilters;
        }

      }
    },

    startDataFetchTicker(timeout) {
      // first request data so it is displayed instantly, then wait for time intervals
      wsRepository.getLiveStreams();

      if (this.fetchDataTicker === null) {
        this.fetchDataTicker = setInterval(() => {
          wsRepository.getLiveStreams();
        }, timeout);
      }
    },

    setLiveStreamActive(liveStreamId, isActive) {
      const circleElem = document.querySelector(`#status-circle-${liveStreamId}`);
      if (!circleElem) throw new Error(`No status circle element with id ${circleElem}`);

      if (isActive) {
        circleElem.classList.add('active');
      } else {
        circleElem.classList.remove('active');
      }
    },
  },

  computed: {
    ...mapGetters([
      constants.Getters.LIVE_STREAMS,
      constants.Getters.ROLES,
      constants.Getters.TEAMS,
      constants.Getters.TEAMS_NOT_REFERENCED_BY_LIVESTREAMS,
    ]),
    ...mapGetters({
      races: constants.Getters.ALL_EVENTS_RACES
    }),
  },

  async created() {
    try {
      await this.getAllRoles();
    } catch(err) {
      console.error('Could not fetch roles', err);
    }

    try {
      await this.getAllTeams();
    } catch(err) {
      console.error('Could not fetch teams', err);
    }

    try {
      await this.getAllTeamsNotReferencedByLiveStreams();
    } catch(err) {
      console.error('Could not fetch teams not referenced by liveStreams', err);
    }

    try {
      await this.getAllEventsRaces();
    } catch(err) {
      console.error('Could not fetch races', err);
    }

    try {
      this.getAllLiveStreams();
    } catch(err) {
      console.error('Could not fetch livestreams', err);
    }

    this.registerWsHandlers();
    this.startDataFetchTicker(2000);
  },
}
</script>

<style lang="scss" scoped>
  // disables focus on button after clicking on it:
  // https://stackoverflow.com/questions/57830767/is-it-default-for-vuetify-to-keep-active-state-on-buttons-after-click-how-do-yo
  .remove-btn:focus:before {
    opacity: 0 !important;
  }

  .status-circle {
    background-color: tomato;

    &.active {
      background-color: rgb(84, 185, 84);
    }
  }

  .lv-container {
    &.active {
      border: 2px solid #67D2CB;
    }
  }

  .alert {
    position: fixed;
    top: 10%;
    left: 50%;
    z-index: 100;

    &.error {
      left: 30%;
    }
  }
</style>