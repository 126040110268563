<template>
  <v-container>
    <v-row v-if="showTeamDeletionErrors">
      <v-alert v-for="(teamDeletionError, index) in teamDeletionErrors" :key="index" transition="fade-transition" type="error" class="alert">
        Impossible de supprimer l'équipe: "{{ teamDeletionError }}"
      </v-alert>
    </v-row>

    <v-row v-if="showRoleDeletionErrors">
      <v-alert v-for="(roleDeletionError, index) in roleDeletionErrors" :key="index" transition="fade-transition" type="error" class="alert">
        Impossible de supprimer le rôle: "{{roleDeletionError }}"
      </v-alert>
    </v-row>

    <v-row>
      <v-col cols="6">
        <v-row no-gutters class="align-center">
          <v-col cols="1">
            <v-icon
              x-large
              color="primary"
            >
              mdi-bike
            </v-icon>
          </v-col>

          <v-col>
            <h1>Équipes & Roles</h1>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row>
          <v-col cols="3">
            <h3>Équipes</h3>
          </v-col>

          <v-col
            cols="9"
          >
            <v-row
              class="justify-end"
            >
              <v-btn
                rounded
                outlined
                small
                color="secondary"
                @click="handleSaveTeams"
              >
                <v-icon left>
                  mdi-tray-arrow-down
                </v-icon>
                Sauvegarder
              </v-btn>
            </v-row>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row>
          <v-col>
            <v-card elevation="1" class="mt-3">

              <v-card-text class="pa-0" v-if="teams">
                <v-form>
                  <v-container>

                    <v-row
                      class="align-center"
                      v-for="(team, i) in teams"
                      :key="i"
                    >

                      <v-col cols="12" md="8" lg="6">
                        <v-row align="baseline">
                          <v-col>
                            <v-text-field
                              label="Nom"
                              single-line
                              :readonly="team.id !== null"
                              v-model="team.name"
                            ></v-text-field>
                          </v-col>

                          <v-col>
                            <v-btn
                              fab
                              x-small
                              rounded
                              outlined
                              color="red"
                              class="remove-btn"
                              @click.prevent="handleDeleteTeam(i)"
                            >
                              <v-icon dark>
                                mdi-close
                              </v-icon>
                            </v-btn>
                          </v-col>

                        </v-row>
                      </v-col>
                    </v-row>

                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-2" justify="end">
          <v-col cols="2">
            <v-btn
              rounded
              outlined
              small
              color="secondary"
              @click="handleAddTeam"
            >
              <v-icon left>
                mdi-plus-circle-outline
              </v-icon>
              Nouvelle Équipe
            </v-btn>
          </v-col>
        </v-row>

      </v-col>
    </v-row>

    <v-row>
      <v-col class="mt-12">
        <v-row>
          <v-col cols="3">
            <h3>Roles</h3>
          </v-col>

          <v-col
            cols="9"
          >
            <v-row
              class="justify-end ml-6"
            >
              <v-btn
                rounded
                outlined
                small
                color="secondary"
                @click="handleSaveRoles"
              >
                <v-icon left>
                  mdi-tray-arrow-down
                </v-icon>
                Sauvegarder
              </v-btn>
            </v-row>
          </v-col>
        </v-row>

        <v-divider></v-divider>

        <v-row>
          <v-col>
            <v-card elevation="1" class="mt-3">

              <v-card-text class="pa-0" v-if="roles">
                <v-form>
                  <v-container>

                    <v-row
                      class="align-center"
                      v-for="(role, i) in roles"
                      :key="i"
                    >
                      <v-col cols="3">
                        <v-text-field
                          label="Nom"
                          single-line
                          v-model="role.name"
                          :readonly="role.id !== null"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="8">
                        <v-text-field
                          label="Icône (URL)"
                          single-line
                          v-model="role.iconURL"
                          :readonly="role.id !== null"
                        ></v-text-field>
                      </v-col>

                      <v-col>
                        <v-btn
                          fab
                          x-small
                          rounded
                          outlined
                          color="red"
                          class="remove-btn"
                          @click.prevent="handleDeleteRole(i)"
                        >
                          <v-icon dark>
                            mdi-close
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>

                  </v-container>
                </v-form>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row class="mt-2" justify="end">
          <v-col cols="2">
            <v-btn
              rounded
              outlined
              small
              color="secondary"
              @click="handleAddRole"
            >
              <v-icon left>
                mdi-plus-circle-outline
              </v-icon>
              Nouveau Role
            </v-btn>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import constants from '@/store/constants';
import { mapActions, mapGetters } from 'vuex';
import WebSocketRepository from '@/api/webSockets/WebSocketRepository';
const wsRepository = new WebSocketRepository();

export default {
  data() {
    return {
      teamsIDsToRemoveFromApi: [],
      rolesIDsToRemoveFromApi: [],

      showTeamDeletionErrors: false,
      teamDeletionErrors: [],

      showRoleDeletionErrors: false,
      roleDeletionErrors: [],
    }
  },

  methods: {
    ...mapActions([
      constants.Actions.GET_ALL_TEAMS,
      constants.Actions.CREATE_TEAMS,
      constants.Actions.DELETE_TEAMS,

      constants.Actions.GET_ALL_ROLES,
      constants.Actions.CREATE_ROLES,
      constants.Actions.DELETE_ROLES,
    ]),

    isTeamEntryEmpty(teamEntry) {
      return teamEntry.name.trim() === '';
    },

    isRoleEntryEmpty(roleEntry) {
      return roleEntry.name.trim() === '';
    },

    handleAddTeam() {
      const defaultTeamItem = {
        id: null,
        name: null,
      };

      this.teams.push(defaultTeamItem);
    },

    handleAddRole() {
      const defaultRoleItem = {
        id: null,
        name: null,
      };

      this.roles.push(defaultRoleItem);
    },

    async handleSaveTeams() {
      // filter out empty lines
      let newTeams = this.teams.filter((team) => !team.id && team.name);
      // remove id from new teams since we souldn't pass it to create the object from the api
      newTeams = newTeams.map((team) => {
        const clone = Object.assign({}, team);
        delete clone.id;
        return clone;
      });

      try {
        await this.createTeams({
          teams: newTeams,
        });
      } catch(err) {
        console.error("create teams error", err);
      }

      if (this.teamsIDsToRemoveFromApi.length > 0) {
        try {
          // delete teams and receive a list of deletion errors if some teams could not have been deleted
          // could have be done in a better way
          const teamDeletionErrors = await this.deleteTeams({
            teamsIds: this.teamsIDsToRemoveFromApi,
          });

          this.teamDeletionErrors = teamDeletionErrors;
          this.showTeamDeletionErrors = true;
          setTimeout(() => {
            this.showTeamDeletionErrors = false;
          }, 4000);

        } catch(err) {
          console.error("delete teams error", err);
        } finally {
          this.teamsIDsToRemoveFromApi = [];
        }
      }
    },

    async handleSaveRoles() {
      // filter out empty lines
      let newRoles = this.roles.filter((role) => !role.id && role.name);
      // remove id from new roles since we souldn't pass it to create the object from the api
      newRoles = newRoles.map((role) => {
        const clone = Object.assign({}, role);
        delete clone.id;
        return clone;
      });

      try {
        await this.createRoles({
          roles: newRoles,
        });
      } catch(err) {
        console.error("create roles error", err);
      }

      if (this.rolesIDsToRemoveFromApi.length > 0) {
        try {
          // delete roles and receive a list of deletion errors if some roles could not have been deleted
          // could have be done in a better way
          const roleDeletionErrors = await this.deleteRoles({
            rolesIds: this.rolesIDsToRemoveFromApi,
          });

          this.roleDeletionErrors = roleDeletionErrors;
          this.showRoleDeletionErrors = true;
          setTimeout(() => {
            this.showRoleDeletionErrors = false;
          }, 4000);

        } catch(err) {
          console.error("delete roles error", err);
        } finally {
          this.rolesIDsToRemoveFromApi = [];
        }
      }
    },

    handleDeleteTeam(itemIndex) {
      const teamToRemove = this.teams[itemIndex];
      if (!teamToRemove) return;

      // it's a team obj from the API and not one created locally
      if (teamToRemove.id) {
        this.teamsIDsToRemoveFromApi.push(teamToRemove.id);
      }
      this.teams.splice(itemIndex, 1);
    },

    handleDeleteRole(itemIndex) {
      const roleToRemove = this.roles[itemIndex];
      if (!roleToRemove) return;

      // it's a role obj from the API and not one created locally
      if (roleToRemove.id) {
        this.rolesIDsToRemoveFromApi.push(roleToRemove.id);
      }
      this.roles.splice(itemIndex, 1);
    },
  },

  computed: {
    ...mapGetters([constants.Getters.TEAMS]),
    ...mapGetters([constants.Getters.ROLES]),
  },

  async created() {
    try {
      this.getAllTeams();
    } catch(err) {
      console.error('Could not fetch teams', err);
    }

    try {
      this.getAllRoles();
    } catch(err) {
      console.error('Could not fetch roles', err);
    }
  },
}
</script>

<style lang="scss">
  // disables focus on button after clicking on it:
  // https://stackoverflow.com/questions/57830767/is-it-default-for-vuetify-to-keep-active-state-on-buttons-after-click-how-do-yo
  .remove-btn:focus:before {
    opacity: 0 !important;
  }

  .alert {
    position: fixed;
    top: 10%;
    left: 45%;
    z-index: 100;
  }
</style>