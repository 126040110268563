enum Actions {
  LOGIN = "login",
  LOGOUT = "logout",
  UPDATE_ACCESS_TOKEN = "updateAccessToken",

  CREATE_RACE = "createRace",
  UPDATE_RACE = "updateRace",
  DELETE_RACE = "deleteRace",
  GET_RACE = "getRace",
  GET_ALL_RACES = "getAllRaces",
  GET_ALL_EVENTS_RACES = "getAllEventsRaces",
  GET_FETCHED_CROSSING_POINTS = "fetchCrossingPoints",
  LOAD_CROSSING_POINTS = "loadCrossingPoints",

  CREATE_EVENT = "createEvent",
  DELETE_EVENT = "deleteEvent",
  GET_ALL_EVENTS = "getAllEvents",

  CREATE_TEAMS = "createTeams",
  GET_ALL_TEAMS = "getAllTeams",
  GET_ALL_TEAMS_NOT_REFERENCED_BY_LIVESTREAMS = "getAllTeamsNotReferencedByLiveStreams",
  DELETE_TEAMS = "deleteTeams",

  CREATE_ROLES = "createRoles",
  GET_ALL_ROLES = "getAllRoles",
  DELETE_ROLES = "deleteRoles",

  CREATE_LIVE_STREAM = "createLiveStream",
  GET_ALL_LIVE_STREAMS = "getAllLiveStreams",
  RESET_LIVE_STREAM = "resetLiveStream",
  UPDATE_LIVE_STREAM = "updateLiveStream",
  DELETE_LIVE_STREAM = "deleteLiveStream",

  CREATE_INTERVAL = "createInterval",
  GET_ALL_INTERVALS = "getAllIntervals",
  UPDATE_INTERVAL = "updateInterval",
  DELETE_INTERVAL = "deleteInterval",
}

enum Getters {
  AUTHENTICATED = "authenticated",
  USER = "user",
  EVENTS = "events",
  TEAMS = "teams",
  TEAMS_NOT_REFERENCED_BY_LIVESTREAMS = "teamsNotReferencedByLiveStreams",
  ROLES = "roles",
  RACE = "race",
  RACES = "races",
  ALL_EVENTS_RACES = "allEventsRaces",
  LIVE_STREAMS = "liveStreams",
  INTERVALS = "intervals",
  FETCHED_CROSSING_POINTS = "fetchedCrossingPoints",
}

enum Mutations {
  SET_USER = "SET_USER",
  SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN",
  SET_EVENTS = "SET_EVENTS",
  SET_TEAMS = "SET_TEAMS",
  SET_TEAMS_NOT_REFERENCED_BY_LIVESTREAMS = "SET_TEAMS_NOT_REFERENCED_BY_LIVESTREAMS",
  SET_ROLES = "SET_ROLES",
  SET_RACE = "SET_RACE",
  SET_RACES = "SET_RACES",
  SET_ALL_EVENTS_RACES = "SET_ALL_EVENTS_RACES",
  SET_LIVE_STREAMS = "SET_LIVE_STREAMS",
  SET_INTERVALS = "SET_INTERVALS",
  SET_FETCHED_CROSSING_POINTS = "SET_FETCHED_CROSSING_POINTS",
}

export default {
  Actions,
  Getters,
  Mutations,
};
