import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import Race from "../views/events/Race.vue";
import AddRace from "../views/events/AddRace.vue";
import Events from "../views/events/Events.vue";
import Teams from "../views/Teams.vue";
import Live from "../views/Live.vue";
import LiveIntervals from "../views/LiveIntervals.vue";
import CrossingPoints from "../views/CrossingPoints.vue";
import LiveStreams from "../views/LiveStreams.vue";
import Intervals from "../views/Intervals.vue";
import store from "@/store";
import Routes from './Routes';
import constants from "@/store/constants";
import Repository from "@/api/Repository";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: Routes.DASHBOARD,
    component: Dashboard,
    children: [
      {
        path: "events",
        name: Routes.EVENTS,
        component: Events,
      },
      {
        path: "/events/:id/races/new",
        name: Routes.EVENT_ADD_RACE,
        component: AddRace,
      },
      {
        path: "/events/:eventId/races/:raceId",
        name: Routes.RACE,
        component: Race,
        props: true
      },
      {
        path: "/teams",
        name: Routes.TEAMS,
        component: Teams,
        props: true
      },
      {
        path: "/livestreams",
        name: Routes.LIVESTREAMS,
        component: LiveStreams,
        props: true
      },
      {
        path: "/intervals",
        name: Routes.INTERVALS,
        component: Intervals,
      },
      {
        path: "/crossing-points",
        name: Routes.CROSSING_POINTS,
        component: CrossingPoints,
        props: true
      },
    ],
  },
  {
    path: "/login",
    name: Routes.LOGIN,
    component: Login,
    meta: {
      guestUser: true,
    },
  },
  {
    path: "/live/:page",
    name: Routes.LIVE,
    component: Live,
    meta: {
      serviceUser: true,
    },
    props: true
  },
  {
    path: "/live/intervals/:id",
    name: Routes.LIVE,
    component: LiveIntervals,
    meta: {
      serviceUser: true,
    },
    props: true
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async(to, from, next) => {
  // Prevent all routes from being accessed as a non-authenticated user by default
  // only allows access to routes that are set as "guestUser" in meta
  const isLoggedIn = store.getters[constants.Getters.AUTHENTICATED];

  if (to.matched.some((record) => record.meta.guestUser)) {
    if (isLoggedIn && to.matched.some((record) => record.name === Routes.LOGIN)) {
      // redirect already authenticated user to Dashboard if he wants to access login page for ex
      next({ name: Routes.DASHBOARD });
    } else {
      next();
    }
  } else {
    if (isLoggedIn) {
      next();
    } else {
      if (to.matched.some((record) => record.meta.serviceUser)) {
        const secret: string = new URL(document.URL).searchParams.get('secret') || '';
        if (!secret) {
          next({ name: Routes.LOGIN });
          return;
        }

        const isValidServiceSecret = await Repository.validateServiceUserSecret(secret);
        if (isValidServiceSecret) {
          next();
        } else {
          next({ name: Routes.LOGIN });
        }
      } else {
        next({ name: Routes.LOGIN });
      }
    }
  }
});

export default router;
