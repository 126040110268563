import webSocketClient from "./webSocketClient";

class WebSocketRepository {
  private socket;

  constructor() {
    this.socket = webSocketClient;

    // interceptor to log an error when an exception is received
    // custom handlers for specific errors can be implemented here
    this.registerHandler('exception', (e) => {
      throw new Error(
        `${WebSocketRepository.name} received an exception: ${JSON.stringify(e)}`
      );
    });
  }

  registerHandler(eventName: string, handler: (data: any) => any) {
    this.socket.on(eventName, (data) => handler(data));
  }

  getLiveStream(page: number) {
    this.socket.emit('getLiveStream', { page });
  }

  getLiveStreamByTeam(team: string) {
    this.socket.emit('getLiveStreamByTeam', { team });
  }

  getLiveStreams() {
    this.socket.emit('getLiveStreams');
  }

  getInterval(id: number) {
    this.socket.emit('getInterval', { id });
  }

  getIntervals() {
    this.socket.emit('getIntervals');
  }
}

export default WebSocketRepository;
