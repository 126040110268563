<template>
  <div>
    <v-row v-if="showLogo && race && race.logoFileURL" id="logo">
      <v-col>
        <v-img
          max-height="200"
          max-width="200"
          :src="race.logoFileURL"
        ></v-img>
      </v-col>
    </v-row>

    <div id="profileContainer" :style="cssVars">
      <div id="profile" v-html="profileFileContent"></div>

      <div v-if="showCursors">
        <ProfileCursor
          v-show="showCurrPosCursor"
          :xPos="currPosCursorXPos"
          :kms="roundNumberToTwoDecimals(gps.coveredKMs)"
          :color="defaultUiColor"
          textColor="white"
          ref="cursor"
          height="95%"
          :style="{ 'margin-bottom': !showFooter ? '4px' : '44px' }"
        />

        <ProfileCursor
          v-show="showArrivalCursor"
          :xPos="arrivalCursorXPos"
          :kms="race.totalKMs"
          :color="defaultUiColor"
          textColor="white"
          ref="arrivalCursor"
          height="110%"
          :style="{ 'margin-bottom': !showFooter ? '4px' : '44px' }"
        />
      </div>

      <ProfileFooter
        v-if="showFooter"
        :departureText="race.departureCity"
        :infosText="race.infos"
        :arrivalText="race.arrivalCity"
        :color="footerColor"
        textColor="white"
      />
    </div>
  </div>
</template>

<script>
import WebSocketRepository from '@/api/webSockets/WebSocketRepository';
const wsRepository = new WebSocketRepository();
import axios from "axios";
import ProfileCursor from '@/components/ProfileCursor.vue';
import ProfileFooter from '@/components/ProfileFooter.vue';

export default {
  props: ["page"],

  components: { ProfileCursor, ProfileFooter },

  data() {
    return {
      baseColor: '#000d44',
      fillColor: '#f42525',
      footerColor: '#000000',
      defaultUiColor: '#252525',

      fetchDataTicker: null,
      profileFileURL: null,
      profileFileContent: null,

      race: null,
      role: null,
      team: null,
      gps: null,

      showCurrPosCursor: false,
      currPosCursorXPos: null,

      showArrivalCursor: false,
      arrivalCursorXPos: null,

      showLogo: true,
      showFooter: true,
      showCursors: true,
    }
  },
  computed: {
    cssVars() {
      return {
        '--base-color': this.baseColor,
        '--fill-color': this.fillColor,
        '--footer-color': this.footerColor,
        '--default-ui-color': this.defaultUiColor,
      }
    },
  },
  methods: {
    roundNumberToTwoDecimals(number) {
      return Math.round(number * 100) / 100;
    },

    async fetchProfileFileContent() {
      if (!this.profileFileURL) throw new Error(`Cannot fetch profileFileContent since profileFileURL is not set`);

      try {
        const res = await axios.get(this.profileFileURL);
        if (res.status === 200) {
          this.profileFileContent = res.data;
        }
      } catch(err) {
        console.error(`Could not fetch profileFileContent from ${this.profileFileURL}, err: ${err}`);
      }
    },

    async registerWsHandlers() {
      wsRepository.registerHandler('getLiveStream', async(data) => await this.handleGetLiveStreamResp(data));
    },

    async handleGetLiveStreamResp(data) {
      // console.log('getLiveStream resp', data);

      this.race = data.race;
      this.baseColor = this.race.baseColor ? this.race.baseColor : this.baseColor;
      this.fillColor = this.race.fillColor ? this.race.fillColor : this.fillColor;
      this.footerColor = this.race.footerColor ? this.race.footerColor : this.footerColor;
      this.role = data.role;
      this.team = data.team;
      this.gps = data.gps;

      // no profileFile fetched yet or profileFile url updated compared to the local one
      if (!this.profileFileContent || this.profileFileURL !== this.race.profileFileURL) {
        this.profileFileURL = this.race.profileFileURL;

        console.log(`Fetching profileFile content from ${this.race.profileFileURL}`);
        try {
          await this.fetchProfileFileContent();

          if (this.showCursors) {
            this.showCursorAtPoint(
              [...document.querySelectorAll('polygon.st0')].pop(),
              this.$refs.arrivalCursor.$el,
              'arrivalCursorXPos',
              'showArrivalCursor',
              this,
            );
          }

        } catch(err) {
          console.error(err);
        }
      }

      if (this.gps && this.gps.currPointID) {
        const teamCurrPointID = this.gps.currPointID;
        this.fillProfile('#profileContainer > #profile > svg', 'polygon#trk1\\:p', [0, teamCurrPointID]);

        const currPointElement = document.querySelector(`polygon#trk1\\:p${teamCurrPointID}`);
        // sometimes the ref is undefined so we check it, maybe because of components rendering order issue
        if (this.showCursors && this.$refs.cursor) {
          this.showCursorAtPoint(currPointElement, this.$refs.cursor.$el, 'currPosCursorXPos', 'showCurrPosCursor', this);
        }
      }
    },

    showCursorAtPoint(polygonPointElement, cursorElement, cursorXPosStateName, showCursorStateName, component) {
      const showCursor = () => {
        const pointElementAbsoluteXPosition = this.getPolygonAbsoluteXPosition(polygonPointElement);
        const cursorXPosition = pointElementAbsoluteXPosition - (cursorElement.getBoundingClientRect().width / 2);
        component[cursorXPosStateName] = cursorXPosition;
        component[showCursorStateName] = true;
      }

      showCursor();
      // re-display the cursor after some timeout because first time it won't display it correctly since
      // the cursor don't have a width the first time (because html kms element is not set yet)
      setTimeout(showCursor, 100);
    },

    getPolygonAbsoluteXPosition(polygonEle) {
      return polygonEle.getBoundingClientRect().x + polygonEle.getBoundingClientRect().width;
    },

    startDataFetchTicker(timeout) {
      // first request data so it is displayed instantly, then wait for time intervals
      wsRepository.getLiveStream(this.page);

      if (this.fetchDataTicker === null) {
        this.fetchDataTicker = setInterval(() => {
          wsRepository.getLiveStream(this.page);
        }, timeout);
      }
    },

    fillProfile(profileSelector, pointsSelectorPrefix, pointsRange) {
      const profileElem = document.querySelector(profileSelector);
      if (!profileElem) throw new Error(`Cannot find profileElem with selector ${profileSelector}`);

      const firstId = pointsRange[0];
      const lastId = pointsRange[1];

      // remove all activated points before update
      profileElem.querySelectorAll(`svg polygon`).forEach((elem) => {
        elem.classList.remove('active');
      });

      for (let i = firstId; i <= lastId; i++) {
        const pointSelector = pointsSelectorPrefix + i;
        const svgPointElem = document.querySelector(pointSelector);

        if (svgPointElem !== null) {
          svgPointElem.classList.add('active');
        }
      }
    },
  },
  created() {
    this.registerWsHandlers();

    this.startDataFetchTicker(4000);

    this.showLogo = !(this.$route.query.logo && this.$route.query.logo === 'false');
    this.showFooter = !(this.$route.query.footer && this.$route.query.footer === 'false');
    this.showCursors = !(this.$route.query.cursors && this.$route.query.cursors === 'false');
  }
}
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

  // /!\ given the dpcumentation "https://vue-loader.vuejs.org/guide/scoped-css.html#deep-selectors"
  // DOM content created with v-html are not affected by scoped styles, but you can still style them using deep selectors.
  // SO SHOULD NOT USE SCOPED STYLE

  // transparent background color so elements can easily be integrated in the vMix software
  body, html, #app {
    background: rgba(0, 0, 0, 0);
  }

  #profileContainer {
    margin: 0;
    width: 100vw;
    padding: 0;
    max-width: 100%;
    position: absolute;
    bottom: 0;
    padding: 0 35px;

    #profile {
      svg {
        polygon.active {
          fill: var(--fill-color);
        }

        .st0 {
          fill: var(--base-color);
          stroke: none;
        }
      }
    }
  }

</style>