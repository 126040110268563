import config from "@/config";
import router from "@/router";
import store from "@/store";
import axios from "axios";
import LocalStorageKeys from "@/utils/localStorage/LocalStorageKeys";
import localStorageUtils from "@/utils/localStorage/localStorageUtils";
import Routes from "../router/Routes";
import constants from "@/store/constants";

const client = axios.create({
  baseURL: config.API_HOST,
});

client.interceptors.request.use((request) => {
  if (request && request.headers) {
    // Do not override authorization header if already exists (when calling "/auth/refresh" with refresh token for ex)
    if (!request.headers["Authorization"]) {
      const localStorageUser = localStorageUtils.getParsedItemtOrNull(
        LocalStorageKeys.USER
      );
      const token = localStorageUser
        ? localStorageUser["tokens"]["access_token"]
        : null;
      if (token) {
        request.headers["Authorization"] = `Bearer ${token}`;
      }
    }

    request.headers["Accept"] = "application/json";
    request.headers["Content-Type"] = "application/json";
  }

  return request;
});

const refreshToken = (refreshToken: string) => {
  return client.post("/auth/refresh", undefined, {
    headers: {
      Authorization: `Bearer ${refreshToken}`,
    },
  });
};

const logoutUser = (logoutFromAPI = false) => {
  store.dispatch(constants.Actions.LOGOUT, { logoutFromAPI });
  return router.push({ name: Routes.LOGIN });
};

client.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status === 401) {
      // prevent infinite loop if receiving 401 when attempted to refresh_token
      if (error.config["url"] == "/auth/refresh") {
        logoutUser();
        return;
      }

      // attempt to obtain a new valid access_token by providing the refresh_token
      const localStorageUser = localStorageUtils.getParsedItemtOrNull(
        LocalStorageKeys.USER
      );
      if (localStorageUser) {
        const { data } = await refreshToken(
          localStorageUser["tokens"]["refresh_token"]
        );
        const accessToken = data["access_token"];
        await store.dispatch(constants.Actions.UPDATE_ACCESS_TOKEN, {
          accessToken,
        });

        // retry original request after having a new access_token
        const originalReq = error.config;
        // remove the old authorization header set so the new request use the new access_token in local storage
        delete originalReq.headers["Authorization"];
        return client.request(originalReq);
      } else {
        // stored token is invalid so only logout on client side
        logoutUser(false);
      }
    }

    return Promise.reject(error);
  }
);

export default client;
