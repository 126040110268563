





















import TheSidebar from "@/components/TheSidebar.vue";
import constants from "@/store/constants";
import Vue from "vue";
import { mapActions } from "vuex";
import Routes from "../router/Routes";

export default Vue.extend({
  name: "Dashboard",
  components: {
    TheSidebar,
  },

  data: () => ({
    drawer: true,
  }),

  methods: {
    ...mapActions([constants.Actions.LOGOUT]),
    async handleLogout() {
      try {
        await this.logout({ logoutFromAPI: true });
      } catch (err) {
        console.log(err);
      } finally {
        this.$router.push({ name: Routes.LOGIN });
      }
    },
  },
});
