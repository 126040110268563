enum Routes {
  DASHBOARD = "dashboard",
  EVENT_ADD_RACE = "event_add_race",
  EVENTS = "events",
  INTERVALS = "intervals",
  LOGIN = "login",
  RACE = "race",
  TEAMS = "teams",
  LIVESTREAMS = "livestreams",
  LIVE = "live",
  LIVE_INTERVALS = "liveIntervals",
  CROSSING_POINTS = "crossingPoints",
}

export default Routes;
